import React from "react";

class PageNotFound extends React.Component {
    render() {
        return (
            <div className="panel">
                <div className="panel-content">
                    <h1>Page Not Found</h1>
                    <p>
                        Whoops! Looks like you stumbled upon a page that does
                        not exist! Try selecting a page to visit through the top
                        navigation bar.
                    </p>
                </div>
            </div>
        );
    }
}

export default PageNotFound;
