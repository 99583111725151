import React from "react";

class DonaldsDock extends React.Component {
    render() {
        return (
            <>
                <h2>Donald's Dock</h2>
                <p>
                    Donald's Dock is a seaside-themed neighborhood that is
                    east of Toontown Central. The playground is famous for
                    its wide open water area and a boat that sails around.
                    The toon-up treasures in the playground are starfish
                    that provide a 10+ laff refill. Donald's Dock is the
                    second neighborhood toons will travel to after
                    completing toontasks in Toontown Central.
                </p>
                <img
                    className="content-img"
                    style={{ height: 320 + "px" }}
                    src="/images/neighborhoods/dock/dock.PNG"
                    alt="Donald's Dock"
                />
                <p>
                    The neighborhood is connected to Toontown Central,
                    Daisy's Garden, The Brrrgh, and Chip N' Dales Acorn
                    Acres. There are 3 main streets that lead to those other
                    neighborhoods:
                </p>
                <ul>
                    <li>Barnacle Boulevard leads to Toontown Central</li>
                    <li>Seaweed Street leads to Daisy's Garden</li>
                    <li>Lighthouse Lane leads to The Brrrgh</li>
                    <li>
                        Chip N' Dales Acorn Acres is accessible via the
                        playground.
                    </li>
                </ul>
                <p>
                    On the streets of Donald's Dock, you will not find a cog
                    higher than level 6, which allows any cog that is not
                    building exclusive to walk the streets. Level 6 cogs
                    become common in all neighborhoods succeeding Donald's
                    Dock.
                </p>
                <h3>Toontasks</h3>
                <p>
                    When starting gag training in Donald's Dock, a toon can
                    choose between the <strong>Drop gag track</strong> or
                    the <strong>Lure gag track</strong>. To earn the chosen
                    gag track and complete the campaign in Donald's Dock, a
                    toon must complete toontasks to earn all 16 Animation
                    frames. Progress on animation frames can be viewed in
                    the Shticker book animation frame page{" "}
                    <span>
                        <img
                            className="book-tab"
                            src="/images/animation_frame_tab.PNG"
                            alt="Animation frame book tab"
                        />
                    </span>
                    . Once all 16 animation frames are earned, a toon can
                    use their new gag track and move onto the next
                    neighborhood, Daisy's Garden.
                </p>
                <p>
                    The toontasks for Donald's Dock, along with earning
                    Animation frames, are as follows:
                </p>
                <ul>
                    <li>Upgrade gag pouch to carry 30 total gags</li>
                    <li>
                        Upgrade jellybean jar to carry 60 total jellybeans
                    </li>
                    <li>Teleport access to Donald's Dock</li>
                    <li>+1 laff boost from two random toontasks</li>
                    <li>+2 laff boost from Billy Budd</li>
                    <li>+2 laff boost from a random toontask</li>
                    <li>+3 laff boost from a random toontask</li>
                </ul>
                <p>
                    There are a total of 9 laff boosts earnable through
                    toontasks in Donald's Dock. A toon can turn in their
                    completed toontasks at Toon HQ. The Toon HQ building in
                    Donald's Dock resembles a small boat wearing a sailor
                    hat and can only be found on water. Toon HQ can be found
                    in the playground or on any street at the halfway point.
                </p>
                <img
                    className="content-img"
                    style={{ height: 300 + "px" }}
                    src="/images/neighborhoods/dock/dock_hq.PNG"
                    alt="Donald's Dock HQ"
                />
                <h3>Clothing Shop</h3>
                <p>
                    The Donald's Dock Clothing Shop is a store in Donald's
                    Dock that sells, well, clothes! It is located left of
                    the Chip N' Dales playground tunnel. Toons may purchase
                    clothing from this shop with a clothing ticket or 200
                    jellybeans. A clothing ticket is earned through a 'Just
                    for Fun!' toontask.
                </p>
                <img
                    className="content-img"
                    style={{ height: 275 + "px" }}
                    src="/images/neighborhoods/dock/dock_clothing.PNG"
                    alt="Donald's Dock clothing store"
                />
                <p>
                    Every playground offers different shirt, shorts, and
                    skirt styles. Below is a list of the clothing options in
                    the Donald's Dock Clothing Shop. Some clothing is
                    exclusive to the gender of the toon, but most clothing
                    is offered to every toon. Clothing can typically be
                    purchased in any color of one's desire.
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Clothing</th>
                            <th>Type</th>
                            <th>Toon Gender</th>
                            <th>Pick Color?</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/dock/Shirt_68.png"
                                    alt="Red button up"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>All</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/dock/Shirt_131.png"
                                    alt="#19 shirt"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>All</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/dock/Ddshirt.png"
                                    alt="Shirt with yellow star logo"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>All</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/dock/Ddmale.png"
                                    alt="White cargo shorts"
                                />
                            </td>
                            <td>Shorts</td>
                            <td>Male</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/dock/Ddfemale.png"
                                    alt="Dotted skirt"
                                />
                            </td>
                            <td>Skirt</td>
                            <td>Female</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/dock/Ddfemale0.png"
                                    alt="White pocket shorts with flower"
                                />
                            </td>
                            <td>Shorts</td>
                            <td>Female</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/dock/Ddmale0.png"
                                    alt="Blue and white leaf shorts"
                                />
                            </td>
                            <td>Shorts</td>
                            <td>Male</td>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default DonaldsDock;
