import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "../scss/about.scss";
import Search from "../components/Search";

const keywords = {
    Doodles: [
        "doodles",
        "doodle",
        "pet",
        "tricks",
        "trick",
        "sos",
        "training",
        "moods",
        "mood",
        "trait",
        "traits",
        "adopt",
        "adopting",
        "adopted",
        "adopts",
        "petshop",
        "pets",
        "shop",
    ],
    Fishing: [
        "fishing",
        "fish",
        "fisherman",
        "fishes",
        "bucket",
        "pond",
        "ponds",
        "playground",
        "where",
        "to",
        "find",
        "species",
        "finding",
        "rod",
        "rods",
        "bingo",
        "cast",
        "dock",
        "docks",
        "trout",
        "devil ray",
        "shark",
        "crab",
        "eel",
        "mackerel",
        "jellyfish",
        "tuna",
        "acuda",
        "horse",
        "petshop",
        "sell",
        "trophies",
        "trophy",
        "reward",
        "rewards",
    ],
    Gardening: [
        "garden",
        "gardening",
        "gardens",
        "water",
        "watering",
        "can",
        "cans",
        "kit",
        "how",
        "to",
        "plant",
        "plants",
        "flowers",
        "jellybeans",
        "jellybean",
        "combo",
        "combos",
        "combination",
        "combinations",
        "flower",
        "bloom",
        "blooms",
        "grow",
        "grows",
        "growing",
        "gag",
        "gags",
        "tree",
        "trees",
        "organic",
        "wheel",
        "barrow",
        "estate",
        "sell",
        "shovel",
        "shovels",
        "statue",
        "statues",
        "trophies",
        "trophy",
        "reward",
        "rewards",
    ],
    Golfing: [
        "golf",
        "golfing",
        "chip",
        "dale",
        "dales",
        "acorn",
        "acres",
        "course",
        "courses",
        "walk",
        "in",
        "the",
        "par",
        "holesome",
        "fun",
        "hole",
        "kit",
        "caboodle",
        "holes",
        "trophies",
        "trophy",
        "rewards",
        "reward",
    ],
    Racing: [
        "race",
        "racing",
        "goofy",
        "speedway",
        "races",
        "tracks",
        "track",
        "grand",
        "prix",
        "trophies",
        "trophy",
        "kart",
        "ticket",
        "tickets",
        "shop",
        "karts",
        "goofys",
        "cruiser",
        "roadster",
        "toon",
        "utility",
        "vehicle",
        "urban",
        "records",
        "rural",
        "circuit",
        "won",
        "qualified",
        "reward",
        "rewards",
    ],
    Bossbots: [
        "bossbot",
        "bossbots",
        "boss",
        "ceo",
        "chief",
        "executive",
        "officer",
        "hq",
        "headquarters",
        "where",
        "office",
        "tower",
        "golf",
        "course",
        "courses",
        "golfing",
        "front",
        "middle",
        "back",
        "three",
        "six",
        "nine",
        "stock",
        "options",
        "version",
        "2.0",
        "disguise",
        "pink",
        "slips",
        "cog",
        "cogs",
        "version",
        "2.0",
        "cog",
        "v2.0",
    ],
    Cashbots: [
        "cashbots",
        "cashbot",
        "cfo",
        "chief",
        "financial",
        "officer",
        "vault",
        "cash",
        "where",
        "disguise",
        "hq",
        "headquarters",
        "unite",
        "unites",
        "train",
        "trainyard",
        "trains",
        "mints",
        "mint",
        "cogbucks",
        "bucks",
        "cogs",
        "cog",
        "dollar",
        "coin",
        "bullion",
        "donalds",
        "dreamland",
    ],
    Lawbots: [
        "lawbots",
        "lawbot",
        "law",
        "cog",
        "cogs",
        "cj",
        "chief",
        "justice",
        "court",
        "courtyard",
        "courthouse",
        "where",
        "brrrgh",
        "summon",
        "summons",
        "disguise",
        "hq",
        "headquarters",
        "da",
        "district",
        "attorneys",
        "attorney",
        "office",
        "offices",
        "jury",
        "notices",
        "notice",
        "puzzle",
        "puzzles",
        "invasion",
        "invasions",
    ],
    Sellbots: [
        "sellbots",
        "sellbot",
        "sell",
        "cog",
        "cogs",
        "vp",
        "senior",
        "vice",
        "president",
        "where",
        "disguise",
        "factory",
        "factories",
        "daisys",
        "daisy",
        "garden",
        "hq",
        "headquarters",
        "merits",
        "merit",
        "foreman",
        "sos",
        "cards",
        "field",
        "office",
        "offices",
    ],
    SellbotFieldOffices: [
        "field",
        "office",
        "offices",
        "sellbot",
        "sellbots",
        "sell",
        "boiler",
        "cubicles",
        "cold",
        "caller",
        "annex",
        "annexes",
        "mega",
        "mover",
        "mega-mover",
        "jokes",
        "floor",
        "surveillance",
        "stomper",
        "room",
        "cog",
        "cogs",
        "remote",
        "control",
        "remotes"
    ],
    SellbotTaskForce: [
        "field",
        "office",
        "offices",
        "sellbot",
        "sell",
        "sellbots",
        "task",
        "force",
        "hideout",
        "underground",
        "resistance",
        "ranger",
        "rangers",
        "ripley",
        "ai",
        "spai",
        "googlymoogly",
        "bonnie",
        "bubbles",
        "operator",
        "operators",
        "trainee",
        "explorer",
        "sleuth",
        "smasher",
        "skill",
        "points",
        "badges",
        "rank",
        "kaboomberg"
    ],
    About: [
        "about",
        "spike",
        "spikes",
        "rewritten",
        "guide",
        "website",
        "toontown",
    ],
    Activities: [
        "activities",
        "activity",
        "gardening",
        "fishing",
        "racing",
        "golfing",
        "silly",
        "meter",
        "teams",
        "trolley",
        "games",
        "game",
        "minigames",
        "mini",
        "minigame",
        "mini-game",
        "mini-games",
        "party",
        "parties",
        "cartoonival",
        "toon",
        "fest",
        "toonfest",
        "reward",
        "rewards",
        "jellybean",
        "jellybeans",
    ],
    Cogs: [
        "cog",
        "cogs",
        "sellbots",
        "cashbots",
        "bossbots",
        "lawbots",
        "sellbot",
        "cashbot",
        "bossbot",
        "lawbot",
        "buildings",
        "hp",
        "health",
        "points",
        "mega",
        "invasion",
        "invasions",
        "tracker",
        "street",
        "battle",
        "battles",
    ],
    Contact: [
        "contact",
        "spike",
        "ask",
        "question",
        "questions",
        "message",
        "discord",
        "help",
        "support",
        "troubleshoot",
    ],
    FAQ: [
        "faq",
        "frequently",
        "asked",
        "questions",
        "question",
        "ask",
        "help",
    ],
    Neighborhoods: [
        "neighborhoods",
        "neighborhood",
        "playground",
        "playgrounds",
        "toontown",
        "central",
        "donalds",
        "dock",
        "donald",
        "dreamland",
        "daisy",
        "daisys",
        "garden",
        "minnie",
        "minnies",
        "melodyland",
        "brrrgh",
        "chip",
        "dale",
        "dales",
        "acorn",
        "acres",
        "toontasks",
        "toon",
        "task",
        "tasks",
        "toontask",
        "clothing",
        "shop",
        "clothes",
        "streets",
        "street",
        "gag",
        "training",
        "shirts",
        "shorts",
        "skirt",
        "skirts",
        "shirt",
    ],
    Toontips: [
        "toon",
        "tips",
        "toontips",
        "tip",
        "cogs",
        "cog",
        "battle",
        "battles",
        "what",
        "to",
        "do",
        "toontasks",
        "toontask",
        "task",
        "tasks",
        "gags",
        "gag",
        "organic",
        "sellbots",
        "cashbots",
        "bossbots",
        "lawbots",
        "sellbot",
        "cashbot",
        "bossbot",
        "lawbot",
        "multipliers",
        "hq",
        "fishing",
        "special",
        "holiday",
        "secrets",
        "secret",
        "streets",
        "playground",
        "playgrounds",
        "racing",
        "strategy",
        "where",
        "groups",
        "field",
        "offices",
        "office",
        "invasion",
        "invasions",
    ],
    Gags: [
        "gags",
        "gag",
        "pouch",
        "pouches",
        "bag",
        "level",
        "levels",
        "track",
        "toon-up",
        "toonup",
        "toon up",
        "trap",
        "lure",
        "sound",
        "throw",
        "squirt",
        "drop",
        "shop",
        "damage",
        "training",
        "train",
        "experience",
        "skill",
        "points",
        "seven",
        "cake",
        "fog",
        "heal",
    ],
};

const info = {
    Doodles: [
        "Have you ever found yourself wanting to have a fluffy companion to greet you at the estate, and assist you and your friends in cog battles? Well I have the best news! Doodles are the pets of Toontown and a toons best friend. Any toon can adopt a doodle of their choice, as long as they have enough jellybeans!",
        "/doodles",
    ],
    Fishing: [
        "Fishing is a fun way to earn yourself an additional 7 laff points! At any time, in any playground with a pond, which is all of them, a toon can fish to earn jellybeans and to add new species to their fish album. Visit the fishing page to view a helpful fishing guide to catch all species in Toontown!",
        "/fishing",
    ],
    Gardening: [
        "Gardening in Toontown is a great and easy way to earn an additional 4 laff points! Any toon, at any level, can start their own garden with the gardening kit at their estate. The gardening kit is purchasable in Clarabelle's Cattlelog. Once received, a gardening guide will be shown, then tree plots and gardening trays will assemble itself around a toons estate.",
        "/gardening",
    ],
    Golfing: [
        "Golfing is a fun, sometimes tedious, game to play by yourself or with toon friends, and it is yet another way to earn an additional 3 laff points for your toon! Any toon at any level can go to Chip N' Dales Golfing and put those putt-putt skills to play.",
        "/golfing",
    ],
    Racing: [
        "Racing?! In Toontown!? G'arsh! Racing is a super fun activity and an exhilarating way to earn 3 additional laff points. It is also a fantastic way to spend time with toon friends! Are you ready to cross the finish line? Any toon at any level can head on over to the speedway and race all day and all night!",
        "/racing",
    ],
    Bossbots: [
        "Bossbots are one of the four tiers of cogs found in Toontown. This group represents the management of the cogs, being the top of the corporate ladder. They are the top ranked cogs. The Bossbot Headquarters is the home of the Bossbots.",
        "/bossbots",
    ],
    Cashbots: [
        "Cashbots are one of the four tiers of cogs found in Toontown. As an overview, they represent finance and money management. The Cashbot Headquarters is the home of the Cashbots and is most likely the headquarters a toon will visit when they are working on Donald's Dreamland toontasks.",
        "/cashbots",
    ],
    Lawbots: [
        "Lawbots are one of the four tiers of cogs found in Toontown. This group represents law enforcement and federal law operations. The Lawbot Headquarters is the home of the Lawbots.",
        "/lawbots",
    ],
    Sellbots: [
        "Sellbots are one of the four tiers of cogs found in Toontown. As an overview, they represent the selling business and they are the lowest ranked cogs. Despite being the lowest ranked, some Sellbots have powerful attacks, such as the Mover & Shaker and the Mingler, so be careful! The Sellbot Headquarters is the home of the Sellbots and is most likely the first headquarters a toon will visit.",
        "/sellbots",
    ],
    SellbotFieldOffices: [
        "A Sellbot Field Office is a unique kind of a cog building, that could be similar to a mobile cog headquarters. However, unlike regular cog buildings, Field Offices take over Toon Headquarters on any street.",
        "/fieldoffices",
    ],
    SellbotTaskForce: [
        "The Sellbot Task Force is a group of Toon Resistance members that monitor the cogs and their tactics. Their home is within the Sellbot Task Force Hideout.",
        "/taskforce",
    ],
    About: [
        "Spike's Rewritten Guide is a great source for all things Toontown Rewritten. It is here to help new toons and toon veterans navigate through toontasks and other activities. Not only does the site provide helpful game play information, it also strives to provide helpful fishing guides, gardening guides, and experience-based game tips.",
        "/about",
    ],
    Activities: [
        "There are many activities in Toontown to participate in that do not involve cogs! Who knew? You probably did... but now you can learn more! Activities in the game include gardening, fishing, racing, golfing, training doodles, playing trolley games, and going to parties! Additionally, the Silly Meter can make these activities, along with cog fighting, more fun!",
        "/activities",
    ],
    Cogs: [
        "Cogs are the main antagonist in Toontown. They are business robots who are trying to take over Toontown and turn into a boring, gloomy corporate land. There are a total of 32 different types of cogs, with levels ranging from 1 to 12. There are 4 different tiers of cogs: Sellbot, Cashbot, Lawbot, and Bossbot.",
        "/cogs",
    ],
    Contact: [
        "Have a burning question about Toontown? Do you think something on the site needs to be improved? Have a suggestion? Please send them over by filling out the contact form below! Spike will get back to you as soon as possible! Join Spike's Rewritten Guide's Discord!",
        "/contact",
    ],
    FAQ: [
        "Got some questions? Check out the Frequently Asked Questions page! Take a peek at some of the questions to see if that helps solve the riddle in your head.",
        "/faq",
    ],
    Neighborhoods: [
        "Toontown is compromised of neighborhoods, which is a governing area of Toontown with its own theme, puns, streets, and a playground. There are six main neighborhoods where toons will complete toontasks. There are an additional two neighborhoods that are recreational and are mainly used for activities such as racing or golfing.",
        "/neighborhoods",
    ],
    Toontips: [
        "There are a lot of ins and outs to playing Toontown, and every player has their own way of doing things. Over the years, I have discovered some simple, useful tips that enhance the gameplay experience. Some tips include universal toon strategy and some tips include personal preferences.",
        "/toontips",
    ],
    Gags: [
        "Gags, gags, gags! What are gags? Gags are jokes and pranks used by toons to help them defeat cogs. They are essentially a toon's weapon arsenal, except less dangerous sounding.",
        "/gags"
    ],
};

export function Results() {
    const { searchQuery } = useParams();
    const [pages, setPages] = useState([]);
    const [fromNav, setFromNav] = useState(false);

    useEffect(() => {
        var searchQuery_ = searchQuery + "";
        var query = null;
        if (searchQuery_) {
            setFromNav(true);
            query = searchQuery_.split(" ").map(function (value, index, array) {
                return value.replace(/[^a-zA-Z ]/g, "").toLowerCase();
            });
        } else {
            setFromNav(false);
        }

        if (query) {
            var scores = {};

            // give a score to each page
            for (var i = 0; i < query.length; i++) {
                for (const [k, v] of Object.entries(keywords)) {
                    if (v.includes(query[i])) {
                        if (k in scores) {
                            scores[k] += 1;
                        } else {
                            scores[k] = 1;
                        }
                    }
                }
            }

            var tempPages = Object.keys(scores).sort((a, b) => b[1] - a[1]);
            setPages(tempPages);
        }
    }, [searchQuery]);

    return (
        <>
            {
                fromNav ? (
                    <>
                        <h2>
                            Search Results for '
                            {searchQuery}'
                        </h2>
                        {pages.length > 0 ? (
                            <>
                                <p>
                                    {pages.length} result(s) for{" "}
                                    <strong>
                                        "{searchQuery}"
                                    </strong>
                                </p>
                                <div className="search-results">
                                    {pages.map((page) => {
                                        return (
                                            <>
                                                {(
                                                    <Link
                                                        reloadDocument
                                                        className="page-link"
                                                        to={info[page][1]}
                                                    >
                                                        {page.split(/(?=[A-Z])/).join(" ")}
                                                    </Link>
                                                )}
                                                <p>
                                                    {info[page][0]}
                                                    <div className="results-link">
                                                        {(
                                                            <Link
                                                                reloadDocument
                                                                to={info[page][1]}
                                                            >
                                                                https://spikesrewrittenguide.com
                                                                {info[page][1]}
                                                            </Link>
                                                        )}
                                                    </div>
                                                </p>
                                            </>
                                        );
                                    })}
                                </div>
                            </>
                        ) : (
                            <p>
                                No search results for{" "}
                                <strong>
                                    "{searchQuery}"
                                </strong>
                            </p>
                        )}
                    </>
                ) : (
                    <br />
                )
            }
        </>
    );
}

class SearchResults extends React.Component {
    render() {
        return (
            <div className="panel">
                <div className="panel-content">
                    <h1>Search Spike's Rewritten Guide</h1>
                    <Search />
                    <Results />
                </div>
            </div>
        );
    }
}

export default SearchResults;
