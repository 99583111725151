import React from "react";
import { Link } from "react-router-dom";

class ToontownCentral extends React.Component {
    render() {
        return (
            <>
                <h2>Toontown Central</h2>
                <p>
                    Toontown Central, commonly known as TTC, is a
                    neighborhood located in the center of Toontown. Toontown
                    Central is the first playground new toons will visit
                    after finishing the Toontorial. The neighborhood itself
                    provides a bright, colorful, and silly atmosphere. The
                    neighborhood is known for the Mickey Mouse shaped
                    playground area. The toon-up treasures in the playground
                    are ice cream cones and provide a 3+ laff refill.
                </p>
                <img
                    className="content-img"
                    style={{ height: 320 + "px" }}
                    src="/images/neighborhoods/ttc/ttc.PNG"
                    alt="Toontown Central"
                />
                <p>
                    Toontown Central is home to many builldings such as the
                    Toontown Library, Toontown Bank, Toontown Schoolhouse,
                    and Toon Hall. The famous Flippy and the Silly Meter can
                    be found inside Toon Hall! The neighborhood is connected
                    to Donald's Dock, Daisy's Garden, Minnie's Melodyand,
                    and Goofy's Speedway. There are 3 main streets that lead
                    to those other neighborhoods:
                </p>
                <ul>
                    <li>Punchline Place leads to Donald's Dock</li>
                    <li>Silly Street leads to Daisy's Garden</li>
                    <li>Loopy Lane leads to Minnie's Melodyland</li>
                    <li>
                        Goofy's Speedway is accessible via the playground.
                        Learn more about Goofy Speedway{" "}
                        <Link reloadDocument to="/racing">here</Link>!
                    </li>
                </ul>
                <p>
                    On the streets of Toontown Central, you will not find a
                    cog higher than level 3 or a cog building with more than
                    3 floors. The exception is when there are high-level cog
                    invasions.
                </p>
                <h3>Toontorial</h3>
                <p>
                    Every new toon has to go through the Toontorial when
                    starting the game. After creating a new toon, that toon
                    will enter the Toontorial. Here, a new player will learn
                    the basic of the game such as gags, cogs, cog battles,
                    toontasks, the Shticker book, and SpeedChat. Toontorial
                    must be completed first in order to start the main
                    campaign.
                </p>
                <h3>Toontasks</h3>
                <p>
                    After completing the Toontorial, a toon will work
                    through the campaign, earning new <Link reloadDocument to="/gags">gag tracks</Link>{" "}
                    to use as the progress through each neighborhood. A new toon
                    starts out with the Throw gag track and the Squirt gag
                    track. In Toontown Central, a toon can choose between
                    the <strong>Toon-up gag track</strong> or the{" "}
                    <strong>Sound gag track</strong> to start their gag
                    training journey. To earn the chosen gag track and
                    complete the campaign in Toontown Central, a toon must
                    complete toontasks to earn Animation frames. There are
                    16 total Animation frames, where the final one cannot be
                    earned until all other toontasks for a neighborhood is
                    completed. Progress on animation frames can be viewed in
                    the Shticker book animation frame page{" "}
                    <span>
                        <img
                            className="book-tab"
                            src="/images/animation_frame_tab.PNG"
                            alt="Animation frame book tab"
                        />
                    </span>
                    . Once all 16 animation frames are earned, a toon can
                    use their new gag track and move onto the next
                    neighborhood, Donald's Dock.
                </p>
                <img
                    className="content-img"
                    style={{ height: 125 + "px" }}
                    src="/images/sound_training_film.PNG"
                    alt="Sound Training animation film"
                />
                <p style={{ textAlign: "center" }}>
                    <em>Sound Gag Training Animation Frame Film</em>
                </p>
                <p>
                    The toontasks for Toontown Central, along with earning
                    Animation frames, are as follows:
                </p>
                <ul>
                    <li>Upgrade gag pouch to carry 25 total gags</li>
                    <li>
                        Increased toontask capacity to hold two toontasks at
                        once
                    </li>
                    <li>
                        Upgrade jellybean jar to carry 50 total jellybeans
                    </li>
                    <li>Teleport access to Toontown Central</li>
                    <li>+1 laff boost from a random toontask</li>
                    <li>+2 laff boost from Professor Pete</li>
                    <li>+2 laff boost from Honey Haha</li>
                    <li>+2 laff boost from Professor Wiggle</li>
                    <li>+3 laff boost from Sticky Lou</li>
                </ul>
                <p>
                    There are a total of 9 laff boosts earnable through
                    toontasks in Toontown Central. A toon can turn in their
                    completed toontasks at Toon HQ. The Toon HQ building in
                    Toontown Central looks like a fire station, a red/orange
                    brick building wearing a giant fire chief helmet. Toon
                    HQ can be found in the playground or on any street at
                    the halfway point.
                </p>
                <img
                    className="content-img"
                    style={{ height: 300 + "px" }}
                    src="/images/neighborhoods/ttc/ttc_hq.PNG"
                    alt="Toontown Central HQ"
                />
                <h3>Clothing Shop</h3>
                <p>
                    The Toontown Central Clothing Shop is a store in
                    Toontown Central that sells, well, clothes! It is
                    located right next to the Goofy's Speedway Tire Tunnel.
                    Toons may purchase clothing from this shop with a
                    clothing ticket or 200 jellybeans. A clothing ticket is
                    earned through a 'Just for Fun!' toontask.
                </p>
                <img
                    className="content-img"
                    style={{ height: 275 + "px" }}
                    src="/images/neighborhoods/ttc/ttc_clothing.PNG"
                    alt="Toontown Central clothing store"
                />
                <p>
                    Every playground offers different shirt, shorts, and
                    skirt styles. Below is a list of the clothing options in
                    the Toontown Central Clothing Shop. Some clothing is
                    exclusive to the gender of the toon, but most clothing
                    is offered to every toon. Clothing can typically be
                    purchased in any color of one's desire.
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Clothing</th>
                            <th>Type</th>
                            <th>Toon Gender</th>
                            <th>Pick Color?</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/ttc/Shirt_22.png"
                                    alt="Plain white shirt"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>All</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/ttc/Shirt_37.png"
                                    alt="Red shirt with sleeve stripes"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>All</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/ttc/Ttshirt.png"
                                    alt="White and purple striped shirt"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>All</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/ttc/Ttmale.png"
                                    alt="White shorts with belt"
                                />
                            </td>
                            <td>Shorts</td>
                            <td>Male</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/ttc/Ttfemale.png"
                                    alt="Plain white skirt"
                                />
                            </td>
                            <td>Skirt</td>
                            <td>Female</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/ttc/Ttfemale0.png"
                                    alt="White jean skirt"
                                />
                            </td>
                            <td>Skirt</td>
                            <td>Female</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/ttc/Ttbottom.png"
                                    alt="White pants with pockets"
                                />
                            </td>
                            <td>Shorts</td>
                            <td>All</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/ttc/Ttbottom0.png"
                                    alt="White jean shorts"
                                />
                            </td>
                            <td>Shorts</td>
                            <td>All</td>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default ToontownCentral;
