import React from "react";
import { Link } from "react-router-dom";

class Cogs extends React.Component {
    render() {
        return (
            <div className="panel">
                <div className="panel-content">
                    <h1>Cogs</h1>
                    <p>
                        Cogs are the main antagonist in Toontown. They are
                        business robots who are trying to take over Toontown and
                        turn into a boring, gloomy corporate land. Toons are
                        introduced to cogs in the toontorial, where they will
                        first encounter a Flunky (Bossbot). Cogs have their own
                        attacks in battle, with the intention of making toons go
                        sad. Higher level cogs tend to have attacks that deal
                        more damage.
                    </p>
                    <p>
                        There are a total of 32 different types of cogs, with
                        levels ranging from 1 to 12. There are 4 different tiers
                        of cogs: Sellbot, Cashbot, Lawbot, and Bossbot. Each
                        tier has its own boss. Toons can fight each boss by
                        building a cog disguise for each cog tier. Learn more
                        about each cog tier from the options below.
                    </p>
                    <div
                        className="row row-images"
                        style={{ textAlign: "center" }}
                    >
                        <div className="column">
                            <Link reloadDocument to="/sellbots">
                                <h2>Sellbots</h2>
                                <img
                                    className="content-img"
                                    style={{ height: 120 + "px" }}
                                    src="/images/cogs/sellbots/sbhq.PNG"
                                    alt="Sellbot HQ"
                                />
                            </Link>
                        </div>
                        <div className="column">
                            <Link reloadDocument to="/cashbots">
                                <h2>Cashbots</h2>
                                <img
                                    className="content-img"
                                    style={{ height: 120 + "px" }}
                                    src="/images/cogs/cashbots/cbhq.PNG"
                                    alt="Cashbot HQ"
                                />
                            </Link>
                        </div>
                        <div className="column">
                            <Link reloadDocument to="/lawbots">
                                <h2>Lawbots</h2>
                                <img
                                    className="content-img"
                                    style={{ height: 120 + "px" }}
                                    src="/images/cogs/lawbots/lbhq.PNG"
                                    alt="Lawbot HQ"
                                />
                            </Link>
                        </div>
                        <div className="column">
                            <Link reloadDocument to="/bossbots">
                                <h2>Bossbots</h2>
                                <img
                                    className="content-img"
                                    style={{ height: 120 + "px" }}
                                    src="/images/cogs/bossbots/bbhq.PNG"
                                    alt="Bossbot HQ"
                                />
                            </Link>
                        </div>
                    </div>
                    <br />
                    <hr />
                    <div className="panel-nav">
                        <a href="#buildings">Cog Buildings</a>
                        <a href="#hp">Cog Health Points</a>
                        <a href="#invasions">Cog Invasions</a>
                        <a href="#tracker">Street Cog Tracker</a>
                    </div>
                    <span className="anchor" id="buildings"></span>
                    <h2>Cog Buildings</h2>
                    <p>
                        Cogs can take over toon buildings on the streets of
                        Toontown. When a cog walks into a toon building, it
                        signals that the toon building is about to be taken over
                        by a cog building. There are four different types of cog
                        buildings: Sellbot, Cashbot, Lawbot, and Bossbot. If a
                        Bossbot, a Head Hunter for example, walks into a toon
                        building, that means the cog building to be will be a
                        Bossbot building.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 325 + "px" }}
                        src="/images/cog_bldg.PNG"
                        alt="Cog buildings (bossbot and lawbot)"
                    />
                    <p>
                        Toons can recover toon buildings by defeating all of the
                        cogs inside of the cog building. Toons can defeat
                        buildings by themselves or with up to 3 other toons. If
                        a single toon or a group of toons defeat a cog building,
                        their names and pictures will be hung up inside the
                        recovered toon building. If a toon saves enough
                        buildings, without them being re-taken over, the toon
                        may earn a bronze, silver, or gold star above their
                        head.
                    </p>
                    <h4>Cog Building Reward Stars</h4>
                    <ol>
                        <li>Bronze Star (defeat 10 floors)</li>
                        <li>Bronze Spinning Star (defeat 20 floors)</li>
                        <li>Silver Star (defeat 30 floors)</li>
                        <li>Silver Spinning Star (defeat 50 floors)</li>
                        <li>Gold Star (defeat 75 floors)</li>
                        <li>Gold Spinning Star (defeat 100 floors)</li>
                    </ol>
                    <h3>Building Difficulties</h3>
                    <p>
                        The difficulty of a cog building depends on the level of
                        the cog that took over the toon building. Cog tier, cog
                        type, and playground does not affect the building
                        difficulty. Levels 1 through 9 cogs roam the streets,
                        meaning there are 9 forms of cog buildings.
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>Difficulty</th>
                                <th># Floors</th>
                                <th>Cog Level Range</th>
                                <th>Boss Level</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>1</td>
                                <td>1-3</td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>1-2</td>
                                <td>2-4</td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>1-3</td>
                                <td>3-5</td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>2-3</td>
                                <td>4-6</td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>2-4</td>
                                <td>5-7</td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>3-4</td>
                                <td>6-8</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>3-5</td>
                                <td>7-9</td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>4-5</td>
                                <td>8-10</td>
                                <td>11</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>5</td>
                                <td>9-11</td>
                                <td>12</td>
                            </tr>
                        </tbody>
                    </table>
                    <h3>Floor Multipliers</h3>
                    <p>
                        Cog buildings have floor XP multipliers that help
                        increase the amount of gag XP and promotion points a
                        toon earns. The multiplier is dependent on which floor
                        the toons are battling on. The higher the floor, the
                        larger the multiplier. If there is a cog invasion
                        active, the multiplier is doubled!
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>Floor</th>
                                <th>Multiplier</th>
                                <th>Multiplier w/ Invasion</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>x1</td>
                                <td>x2</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>x1.5</td>
                                <td>x3</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>x2</td>
                                <td>x4</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>x2.5</td>
                                <td>x5</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>x3</td>
                                <td>x6</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        It is recommended that toons train their gags in cog
                        buildings. This will provide the most reward.
                    </p>
                    <span className="anchor" id="hp"></span>
                    <h2>Cog Health Points</h2>
                    <p>
                        Depending on their level, cogs have a certain amount of
                        health points (HP). Level 1 cogs have the lowest amount
                        of HP while level 12 cogs have the highest amount of HP.
                        Being aware of cog HP allows for wiser gag choices in
                        any cog battle situation. Use the table below for
                        reference!
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>Cog Level</th>
                                <th>Health Points (HP)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>30</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>42</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>56</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>72</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>90</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>110</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>132</td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>156</td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>196</td>
                            </tr>
                            <tr>
                                <td>13 *</td>
                                <td>224</td>
                            </tr>
                            <tr>
                                <td>14 *</td>
                                <td>254</td>
                            </tr>
                            <tr>
                                <td>15 *</td>
                                <td>286</td>
                            </tr>
                            <tr>
                                <td>16 *</td>
                                <td>320</td>
                            </tr>
                            <tr>
                                <td>17 *</td>
                                <td>356</td>
                            </tr>
                            <tr>
                                <td>18 *</td>
                                <td>394</td>
                            </tr>
                            <tr>
                                <td>19 *</td>
                                <td>434</td>
                            </tr>
                            <tr>
                                <td>20 *</td>
                                <td>476</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        Cog bosses such as the V.P., C.F.O., Chief Justice, and
                        C.E.O. have a total of 500 HP.
                    </p>
                    <p>
                        * Cog levels 13 through 20 can only be found in{" "}
                        <Link reloadDocument to="/fieldoffices">Sellbot Field Offices</Link>.
                    </p>
                    <span className="anchor" id="invasions"></span>
                    <h2>Cog Invasions</h2>
                    <p>
                        A cog invasion is an event where a certain cog will
                        invade a district. Sometimes this may be random, but is
                        usually triggered by a toon using a cog summon. A cog
                        summon is a one time use reward received after defeating
                        the Chief Justice. To learn more, head to the{" "}
                        <Link reloadDocument to="/lawbots">Lawbot page</Link>! During a cog
                        invasion, toons will earn double gag XP and double
                        promotion points.
                    </p>
                    <p>
                        A cog invasion will not affect the cogs that toons
                        battle in Cog HQ specific activities. Examples of such
                        activities are factories, mints, district attorney
                        offices, golf courses, and boss battles.
                    </p>
                    <h3>Mega-Invasions</h3>
                    <p>
                        A mega-invasion is an event that last longer than a
                        typical cog invasion. Mega-invasions do not come from
                        cog summons and do not happen randomly. They are
                        scheduled and can last several days. Additionally, these
                        types of invasions can take up mulitple districts,
                        except for safe-zone districts. Sometimes cogs that do
                        not usually roam the streets are the cog in a
                        mega-invasion. These include Skelecogs and Version 2.0
                        cogs.
                    </p>
                    <span className="anchor" id="tracker"></span>
                    <h2>Street Cog Tracker</h2>
                    <p>
                        A cog tracker is a helpful tool that toons can use when
                        roaming the streets and looking for certain cogs or cog
                        buildings. To use the cog tracker, open the Shticker
                        book and navigate to the cog gallery page{" "}
                        <span>
                            <img
                                className="book-tab"
                                src="/images/cog_gallery_tab.PNG"
                                alt="Cog gallery book tab"
                            />
                        </span>
                        . Click on the cog emblem{" "}
                        <span>
                            <img
                                className="book-tab"
                                src="/images/cog_tracker_btn.PNG"
                                alt="Cog gallery book tab"
                            />
                        </span>{" "}
                        for the desired cog type to the left of each tier. This
                        will provide information for how many of each cog is on
                        the street and how many cog buildings for the chosen cog
                        tier is present.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 325 + "px" }}
                        src="/images/cog_tracker_ex.PNG"
                        alt="Cog tracker example"
                    />
                    <h3>How to get the Cog Tracker</h3>
                    <p>
                        To fully obtain the cog tracker for each tier, a toon
                        must reach two cog limits for all cogs of that tier.
                        This means a toon must defeat a certain amount of cogs
                        defined by the limit. Limits will be tracked in the cog
                        gallery, as shown below. When the first limit is reached
                        for all cogs of a certain type, the number of each cog
                        present in the current area is displayed. Then the limit
                        is increased. When the second limit is reached for all
                        cogs in a tier, the number of cog buildings present is
                        shown. The buildings' location will also appear on the
                        street map, designated by the cog type emblem.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 165 + "px" }}
                        src="/images/cog_limits_ex.PNG"
                        alt="Cog limits example"
                    />
                    <br />
                    <br />
                    <div className="panel-nav">
                        <a href="#buildings">Cog Buildings</a>
                        <a href="#hp">Cog Health Points</a>
                        <a href="#invasions">Cog Invasions</a>
                        <a href="#tracker">Street Cog Tracker</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Cogs;
