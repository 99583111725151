import React from "react";

class DaisysGarden extends React.Component {
    render() {
        return (
            <>
                <h2>Daisy's Garden</h2>
                <p>
                    Daisy's Garden, often abbreviated as DG, is a beautiful
                    garden-themed neighborhood that is southwest of Toontown
                    Central. The playground is known for its maze in the
                    middle of the playground. The toon-up treasures in the
                    playground are daisies that provide a 10+ laff refill.
                    Daisy's Garden is the third neighborhood toons will
                    travel to after completing toontasks in Donald' Dock.
                </p>
                <img
                    className="content-img"
                    style={{ height: 320 + "px" }}
                    src="/images/neighborhoods/dg/dg.PNG"
                    alt="Daisy's Garden"
                />
                <p>
                    The neighborhood is connected to Toontown Central,
                    Donald's Dock, and Sellbot HQ. There are 3 main streets
                    that lead to those other neighborhoods or cog
                    headquarters:
                </p>
                <ul>
                    <li>Elm Street leads to Toontown Central</li>
                    <li>Maple Street leads to Donald's Dock</li>
                    <li>Oak Street leads to Sellbot HQ</li>
                </ul>
                <p>
                    On the streets of Daisy's Garden, you will not find a
                    cog higher than level 6, which allows any cog that is
                    not building exclusive to walk the streets.
                </p>
                <h3>Toontasks</h3>
                <p>
                    Daisy's Garden is unique because it is one of two
                    neighborhoods where toons do not train for a new gag
                    track. Daisy's Garden toontasks are dedicated to
                    teaching toons about Cog Headquarters, specifically
                    Sellbot HQ. Toons can earn laff boosts and other
                    upgrades while completing the tasks here. Once all
                    toontasks are completed, a toon can move onto Minnie's
                    Melodyland to start training for another gag track.
                </p>
                <p>The toontasks for Daisy's Garden are as follows:</p>
                <ul>
                    <li>Upgrade gag pouch to carry 35 total gags</li>
                    <li>
                        Increased toontask capacity to hold three toontasks
                        at once
                    </li>
                    <li>
                        Upgrade jellybean jar to carry 80 total jellybeans
                    </li>
                    <li>Teleport access to Daisy's Garden</li>
                    <li>+1 laff boost from Sofie Squirt</li>
                    <li>+1 laff boost from Artie</li>
                    <li>+1 laff boost from Innkeeper Janet</li>
                    <li>+1 laff boost from Barber Bjorn</li>
                    <li>+2 laff boost from Postman Felipe</li>
                    <li>+2 laff boost from a random toontask</li>
                    <li>
                        +3 laff boost from Uncle Mudd, Uncle Spud, or Aunt
                        Hill
                    </li>
                </ul>
                <p>
                    There are a total of 11 laff boosts earnable through
                    toontasks in Daisy's Garden. A toon can turn in their
                    completed toontasks at Toon HQ. The Toon HQ building in
                    Daisy's Garden look like giant pink watering cans with a
                    cute bowl hat on top. Toon HQ can be found in the
                    playground or on any street at the halfway point.
                </p>
                <img
                    className="content-img"
                    style={{ height: 300 + "px" }}
                    src="/images/neighborhoods/dg/dg_hq.PNG"
                    alt="Daisy's Garden HQ"
                />
                <h3>Clothing Shop</h3>
                <p>
                    The Daisy's Garden Clothing Shop is a store in Daisy's
                    Garden that sells, well, clothes! It is located left of
                    the Oak Street tunnel. Toons may purchase clothing from
                    this shop with a clothing ticket or 200 jellybeans. A
                    clothing ticket is earned through a 'Just for Fun!'
                    toontask.
                </p>
                <img
                    className="content-img"
                    style={{ height: 275 + "px" }}
                    src="/images/neighborhoods/dg/dg_clothing.PNG"
                    alt="Daisy's Garden clothing store"
                />
                <p>
                    Every playground offers different shirt, shorts, and
                    skirt styles. Below is a list of the clothing options in
                    the Daisy's Garden Clothing Shop. Some clothing is
                    exclusive to the gender of the toon, but most clothing
                    is offered to every toon. Clothing can typically be
                    purchased in any color of one's desire.
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Clothing</th>
                            <th>Type</th>
                            <th>Toon Gender</th>
                            <th>Pick Color?</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/dg/Shirt_7.png"
                                    alt="White and blue soccer ball shirt"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>All</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/dg/Shirt_57.png"
                                    alt="Leaf button up"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>All</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/dg/Shirt_114.png"
                                    alt="Yellow button up with pockets"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>All</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/dg/Dgshirt.png"
                                    alt="White blouse"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>All</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/dg/Dgmale.png"
                                    alt="White sport shorts with red and black stripe"
                                />
                            </td>
                            <td>Shorts</td>
                            <td>Male</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/dg/Dgfemale.png"
                                    alt="White skirt with stripe on bottom"
                                />
                            </td>
                            <td>Skirt</td>
                            <td>Female</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/dg/Dgfemale0.png"
                                    alt="Flower printed skirt"
                                />
                            </td>
                            <td>Skirt</td>
                            <td>Female</td>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default DaisysGarden;
