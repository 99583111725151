import React from "react";
import { Link } from "react-router-dom";

class FieldOffices extends React.Component {
    render() {
        return (
            <div className="panel">
                <div className="panel-content">
                    <h1>Sellbot Field Offices</h1>
                    <div className="warning-message">
                        <h4>
                            <i class="fas fa-exclamation-triangle"></i>
                            &nbsp;&nbsp;
                            <strong>Attention</strong>
                        </h4>
                        <p>
                            This page contains information and pictures in
                            relation to the Sellbot Task Force update. Before
                            continuing, please know there are spoilers ahead!
                        </p>
                    </div>
                    <p>
                        A Sellbot Field Office is a unique kind of a cog
                        building, that could be similar to a mobile cog
                        headquarters. However, unlike regular cog buildings,
                        Field Offices take over Toon Headquarters on any street.
                        How rude! When a Toon Headquarters is taken over on any
                        given street, it is also taken over in all districts of
                        Toontown. When a group enters one of these menacing
                        buildings, they are transported to an annex. Toons will
                        work together to take down a single annex. Multiple
                        annexes need to be taken down in order for the entire
                        Field Office to be conquered. There are three difficulty
                        levels for these buildings, indicated by stars. One star
                        Field Offices are the easiest while three star Field
                        Offices are the most challenging.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 275 + "px" }}
                        src="/images/cogs/sellbots/fieldoffices/field_office.PNG"
                        alt="Sellbot Task Force Hideout entrance"
                    />
                    <br />
                    <div className="panel-nav">
                        <a href="#runthrough">
                            Runthrough &amp; Gameplay
                        </a>
                        <a href="#floors">Floors &amp; Minigames</a>
                        <a href="#boiler">The Boiler</a>
                        <a href="#remotes">Remote Controls</a>
                    </div>
                    <br />
                    <div style={{ marginTop: 5 + "px", textAlign: "center" }}>
                        <Link reloadDocument to="/taskforce">
                            <button className="task-force-button">
                                Looking for the Sellbot Task Force? Click here!
                            </button>
                        </Link>
                    </div>
                    <span className="anchor" id="runthrough"></span>
                    <h2>Runthrough &amp; Gameplay</h2>
                    <p>
                        When a group of toons enter a Field Office annex, they
                        are greeted by a Resistance Ranger through a transmitted
                        signal. This ranger will inform the toons which three
                        gag tracks are available to them when starting. That's
                        right, within these evil buildings, us toons are limited
                        on our gag choices! Do not panic too much, toons are
                        guaranteed to have throw or squirt as one of the three
                        starting tracks.
                    </p>
                    <p>
                        How do you gain access to other gags tracks you may ask.
                        Easy! Locked gag tracks can be restored by collecting
                        jokes by defeating cogs during the office minigames. For
                        each office minigame, two missing gag tracks can be
                        restored. Any additional jokes collected will be
                        converted to laff. Super useful!
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>Field Office Difficulty</th>
                                <th># of jokes for one gag track</th>
                                <th># of jokes for two gag tracks</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>&#x2605;</td>
                                <td>25</td>
                                <td>40</td>
                            </tr>
                            <tr>
                                <td>&#x2605;&#x2605;</td>
                                <td>35</td>
                                <td>55</td>
                            </tr>
                            <tr>
                                <td>&#x2605;&#x2605;&#x2605;</td>
                                <td>45</td>
                                <td>65</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        While some gag tracks may not be available at any time,
                        toons can still utilize other rewards such as SOS cards,
                        doodles, unites, and pink slips.
                    </p>
                    <iframe
                        className="video-frame"
                        width="560"
                        height="315"
                        src="https://www.youtube-nocookie.com/embed/NRCMlOkcuyw"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                    <h3>Locating a Field Office</h3>
                    <p>
                        As mentioned previously, you can find Field Offices on
                        the streets of Toontown where Toon Headquarter buildings
                        are supposed to be. But if you do not want to run around
                        trying to find a Field Office of a specific difficult,
                        you do not have to! In every Toon Headquarters, there is
                        a "Known Field Offices" chart that gives you all the
                        information you need when looking for a Field Office to
                        battle. Information includes difficulty level, street
                        location, and number of annexes remaining. Super useful!
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 300 + "px" }}
                        src="/images/cogs/sellbots/fieldoffices/known_field_offices.PNG"
                        alt="Known Field Offices Chart"
                    />
                    <span className="anchor" id="floors"></span>
                    <h2>Floors &amp; Minigames</h2>
                    <p>
                        With the exception of the top floor, The Boiler Room,
                        floors and minigames will be randomized in terms of
                        order. The only consistent pattern will be that
                        minigames will fall on the second and fourth floor. A
                        Resistance Ranger will provide a briefing before
                        entering each level.
                    </p>
                    <h4>Stomper Room</h4>
                    <p>
                        Toons will battle a wave of cogs in the Stomper Room
                        before entering one of the minigames.
                    </p>
                    <h4>Surveillance Room</h4>
                    <p>
                        Toons will battle a wave of cogs in the Surveillance
                        Room before entering one of the minigames.
                    </p>
                    <h4>Cold Caller Cubicles</h4>
                    <p>
                        In this minigame, toons need to skate around the
                        cubicles and turn up the heat by enabling thermostats.
                        Cold Callers are determined to keep it cold, so they
                        will constantly attempt to disable the thermostats.
                        Toons can collect and throw snowballs at the Cold
                        Callers to defeat them. There are other cogs present
                        such as Name Droppers and Mover &amp; Shakers. Defeated
                        cogs will drop jokes that toons can pick up.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 200 + "px" }}
                        src="/images/cogs/sellbots/fieldoffices/cold_caller_cubicles.png"
                        alt="Cold Caller Cubicles"
                    />
                    <h4>Mega-Mover Mazes</h4>
                    <p>
                        In this minigame, toons need to battle their way through
                        the maze, grabbing water balloons from water coolers and
                        throwing them at the cogs. Mega-movers are giant Mover
                        &amp; Shakers that need to be defeated in order to move
                        on. These giant, disruptive cogs need to be hit by three
                        water balloons to be defeated. Be careful around them,
                        they shake the building so much, office cabinets fall
                        from the ceiling! Strange... There are other cogs
                        present such as Name Droppers, Telemarketers, and Cold
                        Callers. Defeated cogs will drop jokes that toons can
                        pick up.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 200 + "px" }}
                        src="/images/cogs/sellbots/fieldoffices/mega_mover_maze.png"
                        alt="Mega Mover Maze"
                    />
                    <h4>The Boiler Room</h4>
                    <p>
                        The Boiler Room is the final floor of a Field Office.
                        The Boiler is the cog boss of a Field Office,
                        practically scarier than the C.E.O.! Defeating the
                        Boiler is needed to defeat a Field Office annex and win.
                        After defeating The Boiler, toons will be rewarded with{" "}
                        <a href="#remotes">remote controls</a>.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 200 + "px" }}
                        src="/images/cogs/sellbots/fieldoffices/boiler_room.png"
                        alt="The Boiler Room"
                    />
                    <span className="anchor" id="boiler"></span>
                    <h2>The Boiler</h2>
                    <p>
                        The Boiler is a boss cog found in Field Offices. This
                        cog oversees the Boiler Room, the final floor. Unlike
                        other boss cogs, The Boiler has many unique attacks and
                        abilities. The Boiler can only be attacked with throw,
                        squirt, and sound. With that in mind, be sure to save
                        some birthday cakes and storm clouds for the final
                        level! The difficulty level of the Field Office in turn
                        determines the difficulty level of The Boiler.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 300 + "px" }}
                        src="/images/cogs/sellbots/fieldoffices/Angry_Boiler.png"
                        alt="Sellbot HQ Courtyard"
                    />
                    <h3>Phases</h3>
                    <p>
                        The Boiler essentially has three different phases that
                        toons should be made aware of and considered for
                        gameplay strategy. The Boiler is in a neutral phase for
                        the first two rounds, with four cogs coming to help in
                        the second round. After this, The Boiler will alternate
                        between the Fired Up phase and the Defense Strategy
                        phase.
                    </p>
                    <h4>Fired Up</h4>
                    <p>
                        This offensive phase is when The Boiler's attacks deal a
                        lot of damage to toons. Additionally, each time this
                        phase begins, present cogs will be provided a percent
                        increase of dealt damage to toons. The good news is,
                        gags used against The Boiler will deal increased damage.
                        Might be a great time to use a birthday or wedding cake.
                    </p>
                    <h4>Defense Strategy</h4>
                    <p>
                        This defensive phase is when The Boiler will retaliate
                        harshly if attacked by a gag and promotes undamaged cogs
                        to a higher level. Try not to attack during this phase,
                        unless you are confident the attacks will defeat The
                        Boiler.
                    </p>
                    <h3>Attacks &amp; Abilities</h3>
                    <p>
                        As mentioned before, The Boiler has unique attacks and
                        abilities, never before seen in other cog boss battles.
                        Attacks are used against toons while abilities are used
                        to help the cogs. Remember, attacks deal more damager
                        when in the Fired Up phase.
                    </p>
                    <h4>Attacks</h4>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: 30 + "%" }}>Attack</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Under Pressure</td>
                                <td>
                                    The Boiler sets off a gear trap that appears
                                    in-between one toon, proceeded by a stomper
                                    coming down and squishing that toon.
                                </td>
                            </tr>
                            <tr>
                                <td>Heated Discussion</td>
                                <td>A group attack from The Boiler.</td>
                            </tr>
                            <tr>
                                <td>Slow Burn</td>
                                <td>
                                    The Boiler inflicts damage over time that
                                    causes Toons to automatically be damaged for
                                    3 rounds.
                                </td>
                            </tr>
                            <tr>
                                <td>Stamp of Disapproval</td>
                                <td>
                                    The Boiler sets off a gear spring flinging
                                    toons and crushing them with a stomper that
                                    applies a stamp as toons fall. Only seen
                                    when attacked in Defensive Strategy.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h4>Abilities</h4>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: 30 + "%" }}>Ability</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Promoted</td>
                                <td>
                                    The Boiler promotes undamaged Cogs by
                                    increasing their level. Only seen in
                                    Defensive Strategy.
                                </td>
                            </tr>
                            <tr>
                                <td>Pay Raise</td>
                                <td>
                                    The Boiler spawns coins on targeted Cogs to
                                    allow them to repair over time for 3 rounds.
                                </td>
                            </tr>
                            <tr>
                                <td>Market Research</td>
                                <td>
                                    A large chart displaying data on stolen
                                    jokes is shown, giving increased defense and
                                    accuracy to all Cogs.
                                </td>
                            </tr>
                            <tr>
                                <td>Retention Plan</td>
                                <td>
                                    The Boiler removes Lure from any lured cogs.
                                </td>
                            </tr>
                            <tr>
                                <td>Quality Control</td>
                                <td>
                                    The Boiler inflicts Gag Ordinance and
                                    consumes all gags of any one track,
                                    preventing toons from using that gag track
                                    for a given number of rounds.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span className="anchor" id="remotes"></span>
                    <h2>Remote Controls</h2>
                    <p>
                        A remote control is a device toons can use to control
                        cogs in a battle. These remotes can be used for damage
                        or healing. Damage remotes, when used, take over control
                        of a chosen cog and forces that cog to damage all other
                        cogs in the battle when its their turn to make a move.
                        Healing remote are similar to damage remotes, except the
                        controlled cog will heal all toons in the battle. For
                        both types of remotes, at expiration, the controlled cog
                        will receive self-damage.
                    </p>
                    <h4>Remote Control Stats</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Level</th>
                                <th>Rounds Active</th>
                                <th>Damage</th>
                                <th>Toon-up</th>
                                <th>Self-damage on Expire</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Damage</td>
                                <td>&#x2605;</td>
                                <td>2</td>
                                <td>45</td>
                                <td>N/A</td>
                                <td>45</td>
                            </tr>
                            <tr>
                                <td>Damage</td>
                                <td>&#x2605;&#x2605;</td>
                                <td>3</td>
                                <td>60</td>
                                <td>N/A</td>
                                <td>90</td>
                            </tr>
                            <tr>
                                <td>Damage</td>
                                <td>&#x2605;&#x2605;&#x2605;</td>
                                <td>4</td>
                                <td>75</td>
                                <td>N/A</td>
                                <td>150</td>
                            </tr>
                            <tr>
                                <td>Healing</td>
                                <td>&#x2605;</td>
                                <td>2</td>
                                <td>N/A</td>
                                <td>15</td>
                                <td>45</td>
                            </tr>
                            <tr>
                                <td>Healing</td>
                                <td>&#x2605;&#x2605;</td>
                                <td>3</td>
                                <td>N/A</td>
                                <td>20</td>
                                <td>90</td>
                            </tr>
                            <tr>
                                <td>Healing</td>
                                <td>&#x2605;&#x2605;&#x2605;</td>
                                <td>4</td>
                                <td>N/A</td>
                                <td>25</td>
                                <td>150</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        Toons are rewarded remote controls after defeating a
                        Field Office annex. There are one, two, and three star
                        remotes which is directly related to the Field Office
                        difficulty. Toons are guaranteed to receive at least one
                        three star remote when defeating a three star Field
                        Office annex. To view collected remote controls, go to
                        the Inventory section{" "}
                        <span>
                            <img
                                className="book-tab"
                                src="/images/gags_tab.PNG"
                                alt="Inventory book tab"
                            />
                        </span>{" "}
                        of the Shticker Book, through the Rewards tab. Select
                        the remote control{" "}
                        <span>
                            <img
                                className="book-tab"
                                src="/images/remote_control_tab.PNG"
                                alt="Remote control button"
                            />
                        </span>{" "}
                        tab. All remote controls collected are viewable here.
                        The remote rewards are organized by type and level.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 250 + "px" }}
                        src="/images/remote_control_options.PNG"
                        alt="Remote control options"
                    />
                    <p>
                        To use a remote control during any cog battle, click the
                        suitcase{" "}
                        <span>
                            <img
                                className="book-tab"
                                src="/images/battle_rewards.PNG"
                                alt="Battle suitcase to access rewards"
                            />
                        </span>{" "}
                        button to the right of the gag choice menu then select
                        the remote control{" "}
                        <span>
                            <img
                                className="book-tab"
                                src="/images/remote_control_tab.PNG"
                                alt="Remote control button"
                            />
                        </span>{" "}
                        tab. Then, choose which remote control you would want to
                        use. After that, choose which cog to control by
                        selecting the arrow pointing to that cog. These remotes
                        can be used in any type of cog battle, however, it is
                        recommended to save them for The Boiler Room or when you
                        need to keep a cog around for several rounds.
                    </p>
                    <br />
                    <div className="panel-nav">
                        <a href="#runthrough">
                            Runthrough &amp; Gameplay
                        </a>
                        <a href="#floors">Floors &amp; Minigames</a>
                        <a href="#boiler">The Boiler</a>
                        <a href="#remotes">Remote Controls</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default FieldOffices;
