import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.handleModeChange = this.handleModeChange.bind(this);
    }

    componentDidMount() {
        const themeValue = localStorage.getItem('theme-value');
        if (themeValue)
            document.getElementById("main").className = themeValue;
    }

    getCurrentYear() {
        return String(new Date().getFullYear());
    }

    handleModeChange(value) {
        document.getElementById("main").className = value;
        localStorage.setItem('theme-value', value);
    }

    render() {
        return (
            <>
                <div className="footer-panel">
                    <div className="row">
                        <div
                            className="site-info column"
                            style={{ marginRight: 30 + "px" }}
                        >
                            <div className="site-title">
                                Spike's Rewritten Guide &copy;{" "}
                                {this.getCurrentYear()}
                            </div>
                            <div className="site-description">
                                A one stop site with all the in's and out's of
                                Toontown Rewritten
                            </div>
                            <br />
                            <div className="theme-select-container">
                                <select
                                    className="theme-select"
                                    id="theme-select-box"
                                    onChange={(event) =>
                                        this.handleModeChange(
                                            event.target.value
                                        )
                                    }
                                >
                                    <option value="light-mode">
                                        Toon Mode
                                    </option>
                                    <option value="dark-mode">Cog Mode</option>
                                </select>
                                <br />
                                <br />
                            </div>
                            <div className="social-links">
                                <a
                                    href="https://www.buymeacoffee.com/spikesrewrittenguide"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="coffee"
                                >
                                    <span role="img" aria-label="Pie emoji">🥧</span>{" "}
                                    Buy me a gag
                                </a>
                                <a
                                    href="https://www.youtube.com/channel/UCkPqlmYEkL9ELhRHV7TWS9w"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ paddingTop: 2 + "px" }}
                                >
                                    <i
                                        className="fa fa-youtube-play"
                                        style={{ fontSize: 28 + "px" }}
                                    ></i>
                                </a>
                            </div>
                        </div>
                        <div className="main-pages column">
                            <Link reloadDocument to="/">Home</Link>
                            <br />
                            <br />
                            <Link reloadDocument to="/activities">Activities</Link>
                            <br />
                            <br />
                            <Link reloadDocument to="/cogs">Cogs</Link>
                            <br />
                            <br />
                            <Link reloadDocument to="/neighborhoods">Neighborhoods</Link>
                            <br />
                            <br />
                            <Link reloadDocument to="/toontips">Toontips</Link>
                        </div>
                        <div className="footer-pages column">
                            <Link reloadDocument to="/about">About</Link>
                            <br />
                            <br />
                            <Link reloadDocument to="/faq">FAQ</Link>
                            <br />
                            <br />
                            <Link reloadDocument to="/contact">Contact</Link>
                            <br />
                            <br />
                            <Link reloadDocument to="/privacypolicy">Privacy Policy</Link>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Footer;
