import React from "react";
import { Link } from "react-router-dom";

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchQuery: "",
        };
    }

    render() {
        return (
            <div className="search">
                <input
                    id="SearchQuery"
                    name="SearchQuery"
                    type="search"
                    placeholder="Search Spike's Rewritten Guide"
                    onChange={(event) =>
                        this.setState({ searchQuery: event.target.value })
                    }
                />
                <Link
                    reloadDocument
                    className="search-btn"
                    to={`/search/${this.state.searchQuery}`}
                    replace={true}
                >
                    <i className="fa fa-search"></i>
                </Link>
            </div>
        );
    }
}

export default Search;
