import React from "react";

class MinniesMelodyland extends React.Component {
    render() {
        return (
            <>
                <h2>Minnie's Melodyland</h2>
                <p>
                    Minnie's Melodyland, sometimes abbreviated as MML, is a
                    pink and yellow musical-themed neighborhood that is
                    north of Toontown Central. The toon-up treasures in the
                    playground are music notes that provide a 10+ laff
                    refill. The neighborhood is famous for its long, tedious
                    toontasks such as the dreaded "Whole Cream Pie" task.
                    This task requires a toon to turn in a Whole Cream Pie,
                    so be you've been warned, be sure to upgrade your throw
                    gags! Minnie's Melodyland is the fourth neighborhood
                    toons will travel to after completing toontasks in
                    Daisy's Garden.
                </p>
                <img
                    className="content-img"
                    style={{ height: 320 + "px" }}
                    src="/images/neighborhoods/mml/mml.PNG"
                    alt="Minnie's Melodyland"
                />
                <p>
                    The neighborhood is connected to Toontown Central, The
                    Brrrgh, and Donald's Dreamland. There are 3 main streets
                    that lead to those other neighborhoods:
                </p>
                <ul>
                    <li>Alto Avenue leads to Toontown Central</li>
                    <li>Baritone Boulevard leads to The Brrrgh</li>
                    <li>
                        Tenor Terrace leads to Donald's Dreamland. This is
                        the only street in Toontown that leads to Donald's
                        Dreamland.
                    </li>
                </ul>
                <p>
                    On the streets of Minnie's Melodyland, you will not find
                    a cog higher than level 6, which allows any cog that is
                    not building exclusive to walk the streets.
                </p>
                <h3>Toontasks</h3>
                <p>
                    When starting gag training in Minnie's Melodyland, a
                    toon can choose between the{" "}
                    <strong>Trap gag track</strong> or the{" "}
                    <strong>Toon-up/Sound gag track</strong>, which depends
                    on which gag track a toon already has. To earn the
                    chosen gag track and complete the campaign in Minnie's
                    Melodyland, a toon must complete toontasks to earn all
                    16 Animation frames. Progress on animation frames can be
                    viewed in the Shticker book animation frame page{" "}
                    <span>
                        <img
                            className="book-tab"
                            src="/images/animation_frame_tab.PNG"
                            alt="Animation frame book tab"
                        />
                    </span>
                    . Once all 16 animation frames are earned, a toon can
                    use their new gag track and move onto the next
                    neighborhood, The Brrrgh.
                </p>
                <p>
                    The toontasks for Minnie's Melodyland, along with
                    earning Animation frames, are as follows:
                </p>
                <ul>
                    <li>Upgrade gag pouch to carry 40 total gags</li>
                    <li>
                        Upgrade jellybean jar to carry 100 total jellybeans
                    </li>
                    <li>Teleport access to Minnie's Melodyland</li>
                    <li>+1 laff boost from two random toontasks</li>
                    <li>+2 laff boost from two random toontasks</li>
                    <li>+3 laff boost from a random toontask</li>
                </ul>
                <p>
                    There are a total of 9 laff boosts earnable through
                    toontasks in Minnie's Melodyland. A toon can turn in
                    their completed toontasks at Toon HQ. The Toon HQ
                    building in Minnie's Melodyland resembles a big pink
                    radio wearing giant headphones. Toon HQ can be found in
                    the playground or on any street at the halfway point.
                </p>
                <img
                    className="content-img"
                    style={{ height: 300 + "px" }}
                    src="/images/neighborhoods/mml/mml_hq.PNG"
                    alt="Minnie's Melodyland HQ"
                />
                <h3>Clothing Shop</h3>
                <p>
                    The Minnie's Melodyland Clothing Shop is a store in
                    Minnie's Melodyland that sells, well, clothes! It is
                    located left of the Baritone Boulevard tunnel. Toons may
                    purchase clothing from this shop with a clothing ticket
                    or 200 jellybeans. A clothing ticket is earned through a
                    'Just for Fun!' toontask.
                </p>
                <img
                    className="content-img"
                    style={{ height: 275 + "px" }}
                    src="/images/neighborhoods/mml/mml_clothing.PNG"
                    alt="Minnie's Melodyland clothing store"
                />
                <p>
                    Every playground offers different shirt, shorts, and
                    skirt styles. Below is a list of the clothing options in
                    the Minnie's Melodyland Clothing Shop. Some clothing is
                    exclusive to the gender of the toon, but most clothing
                    is offered to every toon. Clothing can typically be
                    purchased in any color of one's desire. A few clothing
                    items only come in one color.
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Clothing</th>
                            <th>Type</th>
                            <th>Toon Gender</th>
                            <th>Pick Color?</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/mml/Shirt_76.png"
                                    alt="Plain shirt with vest"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>All</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/mml/Shirt_104.png"
                                    alt="Striped shirt"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>All</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/mml/Shirt_143.png"
                                    alt="Polo shirt"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>All</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/mml/Mmshirt.png"
                                    alt="Shirt with flower pattern"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>All</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/mml/Mmshirt0.png"
                                    alt="Plain shirt with jean vest"
                                />
                            </td>
                            <td>Shirt</td>
                            <td>All</td>
                            <td>No</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/mml/Mmmale.png"
                                    alt="White shorts with blue and red individual stripes"
                                />
                            </td>
                            <td>Shorts</td>
                            <td>Male</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/mml/Mmfemale.png"
                                    alt="Vertical striped skirt"
                                />
                            </td>
                            <td>Skirt</td>
                            <td>Female</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src="/images/neighborhoods/mml/Mmfemale0.png"
                                    alt="Denim shorts"
                                />
                            </td>
                            <td>Shorts</td>
                            <td>Female</td>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
    }
}

export default MinniesMelodyland;
