import React from "react";
import { Link } from "react-router-dom";

class Gags extends React.Component {
    render() {
        return (
            <div className="panel">
                <div className="panel-content">
                    <h1>Gags</h1>
                    <p>
                        Gags, gags, gags! What are gags? Gags are jokes and pranks
                        used by toons to help them defeat cogs. They are essentially
                        a toon's weapon arsenal, except less dangerous sounding. Toons
                        will start the game with a Cupcake and a Squirting Flower.
                        To earn new gags, toons must earn experience points and
                        complete toontasks.
                    </p>
                    <div className="panel-nav">
                        <a href="#gags">Gag Levels &amp; Tracks</a>
                        <a href="#buy">Where to Buy Gags</a>
                        <a href="#damage">Damage</a>
                    </div>
                    <span className="anchor" id="gags"></span>
                    <h2>Gag Tracks &amp; Levels</h2>
                    <p>
                        There are currently seven total gag tracks with seven earnable gags in each track. There
                        are unique qualities and benefits to each gag track that make them useful in battle.
                        The assortment of gags tracks each toon has plays a huge role into gameplay strategy.
                    </p>
                    <h3>Gag Types</h3>
                    <h4>Toon-up</h4>
                    <div className="gag-row">
                        <img alt="Feather Icon" src="/images/gags/Feather_Icon.png" />
                        <img alt="Megaphone Icon" src="/images/gags/Megaphone_Icon.png" />
                        <img alt="Lipstick Icon" src="/images/gags/Lipstick_Icon.png" />
                        <img alt="Bamboo Cane Icon" src="/images/gags/Bamboo_Cane_Icon.png" />
                        <img alt="Pixie Dust Icon" src="/images/gags/Pixie_Dust_Icon.png" />
                        <img alt="Juggling Cubes Icon" src="/images/gags/Juggling_Cubes_Icon.png" />
                        <img alt="High Dive Icon" src="/images/gags/High_Dive_Icon.png" />
                    </div>
                    <p>
                        Toon-up gags restore laff points by a certain amount for toons in a battle.
                        A toon cannot use Toon-up on themself. If a multi-toon Toon-up gag is used,
                        its value will be distributed evenly amongst the available toons.
                        Toon-up has medium accuracy and heals others. It does not miss, but rather
                        provides 20% of its total value if "missed".
                        These gags are the first set of gags used in battle.
                    </p>
                    <h4>Trap</h4>
                    <div className="gag-row">
                        <img alt="Banana Peel Icon" src="/images/gags/Banana_Peel_Icon.png" />
                        <img alt="Rake Icon" src="/images/gags/Rake_Icon.png" />
                        <img alt="Marbles Icon" src="/images/gags/Marbles_Icon.png" />
                        <img alt="Quicksand Icon" src="/images/gags/Quicksand_Icon.png" />
                        <img alt="Trapdoor Icon" src="/images/gags/Trapdoor_Icon.png" />
                        <img alt="TNT Icon" src="/images/gags/TNT_Icon.png" />
                        <img alt="Railroad Icon" src="/images/gags/Railroad_Icon.png" />
                    </div>
                    <p>
                        Trap gags are exactly what they sound like. A trap! These gags require the
                        use of Lure to activate the attack. A single cog will fall onto the trap if
                        lured successfully. Note, the level seven Train gag will damage all cogs.
                        Trap gags have perfect accuracy, given a successful lure. These gags cannot be
                        used on a cog that is already lured. Trap is the most powerful gag track and are
                        the second set of gags used in battle.
                    </p>
                    <h4>Lure</h4>
                    <div className="gag-row">
                        <img alt="$1 Bill Icon" src="/images/gags/1_Bill_Icon.png" />
                        <img alt="Small Magnet Icon" src="/images/gags/Small_Magnet_Icon.png" />
                        <img alt="$5 Bill" src="/images/gags/5_Bill_Icon.png" />
                        <img alt="Big Magnet Icon" src="/images/gags/Big_Magnet_Icon.png" />
                        <img alt="$10 Bill Icon" src="/images/gags/10_Bill_Icon.png" />
                        <img alt="Hypno-goggles Icon" src="/images/gags/Hypno-goggles_Icon.png" />
                        <img alt="Presentation Icon" src="/images/gags/Presentation_Icon.png" />
                    </div>
                    <p>
                        Lure gags stun cogs and prevent them attacking for a certain amount of battle
                        rounds. Single cog or "all cogs" lure gags can be used.
                        Lure gags have low accuracy but have a huge gain. It prevents toons from losing
                        additional laff points in a battle and increases damage bonus for Throw and Squirt gags.
                        These gags are the third set of gags used in battle.
                    </p>
                    <h4>Sound</h4>
                    <div className="gag-row">
                        <img alt="Bike Horn Icon" src="/images/gags/Bike_Horn_Icon.png" />
                        <img alt="Whistle Icon" src="/images/gags/Whistle_Icon.png" />
                        <img alt="Bugle Icon" src="/images/gags/Bugle_Icon.png" />
                        <img alt="Aoogah Icon" src="/images/gags/Aoogah_Icon.png" />
                        <img alt="Elephant Trunk Icon" src="/images/gags/Elephant_Trunk_Icon.png" />
                        <img alt="Foghorn Icon" src="/images/gags/Foghorn_Icon.png" />
                        <img alt="Opera Singer Icon" src="/images/gags/Opera_Singer_Icon.png" />
                    </div>
                    <p>
                        Sound gags are noisy gags that hit all cogs in a battle at once. When used with
                        others toon, these gags can deal a lot of damage.
                        Sound gags have high accuracy. This is the most common gag track used and highly
                        sought after when forming groups. Sound gags hit multiple cogs at once but will push
                        back lured cogs. These gags are the fourth set of gags used in battle.
                    </p>
                    <h4>Throw</h4>
                    <div className="gag-row">
                        <img alt="Cupcake Icon" src="/images/gags/Cupcake_Icon.png" />
                        <img alt="Fruit Pie Slice Icon" src="/images/gags/Fruit_Pie_Slice_Icon.png" />
                        <img alt="Cream Pie Slice Icon" src="/images/gags/Cream_Pie_Slice_Icon.png" />
                        <img alt="Whole Fruit Pie Icon" src="/images/gags/Whole_Fruit_Pie_Icon.png" />
                        <img alt="Whole Cream Pie Icon" src="/images/gags/Whole_Cream_Pie_Icon.png" />
                        <img alt="Birthday Cake Icon" src="/images/gags/Birthday_Cake_Icon.png" />
                        <img alt="Wedding Cake Icon" src="/images/gags/Wedding_Cake_Icon.png" />
                    </div>
                    <p>
                        Throw gags are one of two gag tracks that all toon have and start with. These gags
                        hit a single cog. Note, the level seven Wedding Cake gag will damage all cogs.
                        Throw gags have medium accuracy and deal a higher amount of damage than Squirt gags.
                        These gags are the fifth set of gags used in battle.
                    </p>
                    <h4>Squirt</h4>
                    <div className="gag-row">
                        <img alt="Squirting Flower Icon" src="/images/gags/Squirting_Flower_Icon.png" />
                        <img alt="Glass of Water Icon" src="/images/gags/Glass_of_Water_Icon.png" />
                        <img alt="Squirt Gun Icon" src="/images/gags/Squirt_Gun_Icon.png" />
                        <img alt="Seltzer Bottle Icon" src="/images/gags/Seltzer_Bottle_Icon.png" />
                        <img alt="Fire Hose Icon" src="/images/gags/Fire_Hose_Icon.png" />
                        <img alt="Storm Cloud Icon" src="/images/gags/Storm_Cloud_Icon.png" />
                        <img alt="Geyser Icon" src="/images/gags/Geyser_Icon.png" />
                    </div>
                    <p>
                        Squirt gags are the other gag track that all toons have and start with. These gags
                        hit a single cog. Note, the level seven Geyser gag will damage all cogs.
                        Squirt gags have high accuracy but deal a lower amount of damage than Throw.
                        These gags are the sixth set of gags used in battle.
                    </p>
                    <h4>Drop</h4>
                    <div className="gag-row">
                        <img alt="Flower Pot Icon" src="/images/gags/Flower_Pot_Icon.png" />
                        <img alt="Sandbag Icon" src="/images/gags/Sandbag_Icon.png" />
                        <img alt="Anvil Icon" src="/images/gags/Anvil_Icon.png" />
                        <img alt="Big Weight Icon" src="/images/gags/Big_Weight_Icon.png" />
                        <img alt="Safe Icon" src="/images/gags/Safe_Icon.png" />
                        <img alt="Grand Piano Icon" src="/images/gags/Grand_Piano_Icon.png" />
                        <img alt="Toontanic Icon" src="/images/gags/Toontanic_Icon.png" />
                    </div>
                    <p>
                        Drop gags, well, drop a heavy object on top of cogs. These gags target a single cog.
                        Note, the level seven Toontanic gag will damage all cogs.
                        Drop gags have low accuracy, the lowest of all the gag tracks. However, they are
                        the second most powerful gag after Trap. These gags are the seventh set of gags used
                        in battle.
                    </p>
                    <h3>Gag Logistics</h3>
                    <p>
                        Toons will start out with Throw and Squirt. When completing toontasks through
                        each neighborhood, toons can pick up to four gag tracks, meaning they will
                        go without one track. They will end up with six total gags tracks. So choose wisely!
                    </p>
                    <p>
                        The <Link reloadDocument to="/neighborhoods">neighborhoods</Link> page details which gags
                        tracks can be earned in each neighborhood and how, but as a quick reference:
                    </p>
                    <ul>
                        <li><strong>Toontown Central</strong>: Toon-up or Sound</li>
                        <li><strong>Donald's Dock</strong>: Lure or Drop</li>
                        <li><strong>Minnie's Melodyland</strong>: Trap or Toon-up/Sound</li>
                        <ul>
                            <li>This is dependent on what was chosen in Toontown Central</li>
                        </ul>
                        <li><strong>The Brrrgh</strong>: one of the two remaining gag tracks a toon does not have</li>
                    </ul>
                    <p>
                        Once a new gag track is earned, a toon will start out with the level one gag in
                        that track. Just like how all toons start out with the level one throw and squirt
                        gags. To level up, toons can use any gag in battle to earn experience points. Something
                        to be aware of, a gag can only earn experience points on a cog if said cog is the same or
                        higher level than the gag being used. For example, a level five cog will reward experience
                        points to a level four gag. Each gag in a track will have an experience point thresholds
                        to reach in order to earn the next level gag in the track.
                    </p>
                    <h4>Required Experience Points</h4>
                    <table className="required-skill-points">
                        <tbody>
                            <tr>
                                <td>
                                    <strong>Toon-Up</strong>
                                </td>
                                <td>
                                    <img alt="Feather Icon" src="/images/gags/Feather_Icon.png" /><br />0
                                </td>
                                <td>
                                    <img alt="Megaphone Icon" src="/images/gags/Megaphone_Icon.png" /><br />20
                                </td>
                                <td>
                                    <img alt="Lipstick Icon" src="/images/gags/Lipstick_Icon.png" /><br />200
                                </td>
                                <td>
                                    <img alt="Bamboo Cane Icon" src="/images/gags/Bamboo_Cane_Icon.png" /><br />800
                                </td>
                                <td>
                                    <img alt="Pixie Dust Icon" src="/images/gags/Pixie_Dust_Icon.png" /><br />2000
                                </td>
                                <td>
                                    <img alt="Juggling Cubes Icon" src="/images/gags/Juggling_Cubes_Icon.png" /><br />6000
                                </td>
                                <td>
                                    <img alt="High Dive Icon" src="/images/gags/High_Dive_Icon.png" /><br />10000
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Trap</strong>
                                </td>
                                <td>
                                    <img alt="Banana Peel Icon" src="/images/gags/Banana_Peel_Icon.png" /><br />0
                                </td>
                                <td>
                                    <img alt="Rake Icon" src="/images/gags/Rake_Icon.png" /><br />20
                                </td>
                                <td>
                                    <img alt="Marbles Icon" src="/images/gags/Marbles_Icon.png" /><br />100
                                </td>
                                <td>
                                    <img alt="Quicksand Icon" src="/images/gags/Quicksand_Icon.png" /><br />800
                                </td>
                                <td>
                                    <img alt="Trapdoor Icon" src="/images/gags/Trapdoor_Icon.png" /><br />2000
                                </td>
                                <td>
                                    <img alt="TNT Icon" src="/images/gags/TNT_Icon.png" /><br />6000
                                </td>
                                <td>
                                    <img alt="Railroad Icon" src="/images/gags/Railroad_Icon.png" /><br />10000
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Lure</strong>
                                </td>
                                <td>
                                    <img alt="$1 Bill Icon" src="/images/gags/1_Bill_Icon.png" /><br />0
                                </td>
                                <td>
                                    <img alt="Small Magnet Icon" src="/images/gags/Small_Magnet_Icon.png" /><br />20
                                </td>
                                <td>
                                    <img alt="$5 Bill" src="/images/gags/5_Bill_Icon.png" /><br />100
                                </td>
                                <td>
                                    <img alt="Big Magnet Icon" src="/images/gags/Big_Magnet_Icon.png" /><br />800
                                </td>
                                <td>
                                    <img alt="$10 Bill Icon" src="/images/gags/10_Bill_Icon.png" /><br />2000
                                </td>
                                <td>
                                    <img alt="Hypno-goggles Icon" src="/images/gags/Hypno-goggles_Icon.png" /><br />6000
                                </td>
                                <td>
                                    <img alt="Presentation Icon" src="/images/gags/Presentation_Icon.png" /><br />10000
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Sound</strong>
                                </td>
                                <td>
                                    <img alt="Bike Horn Icon" src="/images/gags/Bike_Horn_Icon.png" /><br />0
                                </td>
                                <td>
                                    <img alt="Whistle Icon" src="/images/gags/Whistle_Icon.png" /><br />40
                                </td>
                                <td>
                                    <img alt="Bugle Icon" src="/images/gags/Bugle_Icon.png" /><br />200
                                </td>
                                <td>
                                    <img alt="Aoogah Icon" src="/images/gags/Aoogah_Icon.png" /><br />1000
                                </td>
                                <td>
                                    <img alt="Elephant Trunk Icon" src="/images/gags/Elephant_Trunk_Icon.png" /><br />2500
                                </td>
                                <td>
                                    <img alt="Foghorn Icon" src="/images/gags/Foghorn_Icon.png" /><br />7500
                                </td>
                                <td>
                                    <img alt="Opera Singer Icon" src="/images/gags/Opera_Singer_Icon.png" /><br />10000
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Throw</strong>
                                </td>
                                <td>
                                    <img alt="Cupcake Icon" src="/images/gags/Cupcake_Icon.png" /><br />0
                                </td>
                                <td>
                                    <img alt="Fruit Pie Slice Icon" src="/images/gags/Fruit_Pie_Slice_Icon.png" /><br />10
                                </td>
                                <td>
                                    <img alt="Cream Pie Slice Icon" src="/images/gags/Cream_Pie_Slice_Icon.png" /><br />50
                                </td>
                                <td>
                                    <img alt="Whole Fruit Pie Icon" src="/images/gags/Whole_Fruit_Pie_Icon.png" /><br />400
                                </td>
                                <td>
                                    <img alt="Whole Cream Pie Icon" src="/images/gags/Whole_Cream_Pie_Icon.png" /><br />2000
                                </td>
                                <td>
                                    <img alt="Birthday Cake Icon" src="/images/gags/Birthday_Cake_Icon.png" /><br />6000
                                </td>
                                <td>
                                    <img alt="Wedding Cake Icon" src="/images/gags/Wedding_Cake_Icon.png" /><br />10000
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Squirt</strong>
                                </td>
                                <td>
                                    <img alt="Squirting Flower Icon" src="/images/gags/Squirting_Flower_Icon.png" /><br />0
                                </td>
                                <td>
                                    <img alt="Glass of Water Icon" src="/images/gags/Glass_of_Water_Icon.png" /><br />10
                                </td>
                                <td>
                                    <img alt="Squirt Gun Icon" src="/images/gags/Squirt_Gun_Icon.png" /><br />50
                                </td>
                                <td>
                                    <img alt="Seltzer Bottle Icon" src="/images/gags/Seltzer_Bottle_Icon.png" /><br />400
                                </td>
                                <td>
                                    <img alt="Fire Hose Icon" src="/images/gags/Fire_Hose_Icon.png" /><br />2000
                                </td>
                                <td>
                                    <img alt="Storm Cloud Icon" src="/images/gags/Storm_Cloud_Icon.png" /><br />6000
                                </td>
                                <td>
                                    <img alt="Geyser Icon" src="/images/gags/Geyser_Icon.png" /><br />10000
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Drop</strong>
                                </td>
                                <td>
                                    <img alt="Flower Pot Icon" src="/images/gags/Flower_Pot_Icon.png" /><br />0
                                </td>
                                <td>
                                    <img alt="Sandbag Icon" src="/images/gags/Sandbag_Icon.png" /><br />20
                                </td>
                                <td>
                                    <img alt="Anvil Icon" src="/images/gags/Anvil_Icon.png" /><br />100
                                </td>
                                <td>
                                    <img alt="Big Weight Icon" src="/images/gags/Big_Weight_Icon.png" /><br />500
                                </td>
                                <td>
                                    <img alt="Safe Icon" src="/images/gags/Safe_Icon.png" /><br />2000
                                </td>
                                <td>
                                    <img alt="Grand Piano Icon" src="/images/gags/Grand_Piano_Icon.png" /><br />6000
                                </td>
                                <td>
                                    <img alt="Toontanic Icon" src="/images/gags/Toontanic_Icon.png" /><br />10000
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        A quick tip, as noted in <Link reloadDocument to="/toontips#4">Toon Tips</Link>, there are ways to
                        multiply gag experience points. These methods include fighting in cog invasions, battling
                        in cog headquarters, and defeating tall cog buildings. Another tip, combining cog invasions
                        with cog buildings makes for an even greater multiplier.
                    </p>
                    <span className="anchor" id="buy"></span>
                    <h2>Where to Buy Gags</h2>
                    <p>
                        Toons can purchase gags at Goofy's Gag Shop. Goofy's
                        Gag Shop can be found in every playground, except for Chip N' Dales Acorn Acres
                        and Goofy's Speedway. All gags, with the exception level seven gags, can be purchased at the
                        gag shop with a single jellybean each. Gags can also be purchased at the end of
                        every trolley game.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 350 + "px" }}
                        src="/images/gag_shop.png"
                        alt="Goofy's Gag Shop in TTC"
                    />
                    <h3>Gag Pouches</h3>
                    <p>
                        Toons carry their gags in a gag pouch. This pouch limits a toon on how many gags they can buy
                        and carry at any given time. The size of the gag pouch can be upgraded through
                        toontasks in the main campaign.
                    </p>
                    <ul>
                        <li>Small pouch capacity: 20 gags</li>
                        <li>Medium pouch capacity: 25 gags</li>
                        <li>Large pouch capacity: 30 gags</li>
                        <li>Small bag capacity: 35 gags</li>
                        <li>Medium bag capacity: 40 gags</li>
                        <li>Large bag capacity: 50 gags</li>
                        <li>Small backpack capacity: 60 gags</li>
                        <li>Medium backpack capacity: 70 gags</li>
                        <li>Large backpack capacity: 80 gags</li>
                    </ul>
                    <h3>How To Stock Level Seven Gags</h3>
                    <p>
                        You might be wondering, how do I restock a level seven gag? The answer is simple.
                        Experience points! Confused still? No problem. Even after earning 10000 experience points
                        and maxing a gag track, a toon will have to earn 500 experience points every time they would
                        like to restock a level seven gag. If a given level seven gag is in a toons gag pouch and
                        500 experience points have been earned, the points will remain at 0 and will restock the
                        gag immediately after use.
                    </p>
                    <span className="anchor" id="damage"></span>
                    <h2>Damage</h2>
                    <p>
                        All gags deal a certain amount of damage to cogs, except for Toon-up and Lure gags.
                        Gags range from a minimum to a maximum amount of damage dealt. Minimum damage
                        dealt is when the gag is new and no additional experience points have been earned.
                        Maximum damage dealt is when all experience points required have been earned and the
                        next gag is unlocked. Everything in between is called training. Toons will train their
                        gags to improve damage dealt and get closer to the next gag in a track.
                    </p>
                    <p>
                        Level one to level five for Drop gags and all level seven gags have the same minimum and
                        maximum damage dealt.
                    </p>
                    <br />
                    <br />
                    <div className="panel-nav">
                        <a href="#gags">Gag Levels &amp; Tracks</a>
                        <a href="#buy">Where to Buy Gags</a>
                        <a href="#damage">Damage</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Gags;
