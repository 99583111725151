import React from "react";
import { Link } from "react-router-dom";

class Activities extends React.Component {
    render() {
        return (
            <div className="panel">
                <div className="panel-content">
                    <h1>Activities</h1>
                    <p>
                        There are many activities in Toontown to participate in
                        that do not involve cogs! Who knew? You probably did...
                        but now you can learn more! Activities in the game
                        include gardening, fishing, racing, golfing, training
                        doodles, playing trolley games, and going to parties!
                        Additionally, the Silly Meter can make these activities,
                        along with cog fighting, more fun! Participating in
                        activities is a great way to get away from cog fighting,
                        to take a break from working on toontasks, and to earn
                        fun rewards.
                    </p>
                    <p>
                        The following activities are the most valuable as the
                        rewards often involve gaining more laff points! Please
                        note, doodle related activities do not earn additional
                        laff points. The total amount of laff points that can be
                        earned from Toontown activities is 17. Select from the
                        options below to learn more.
                    </p>
                    <div
                        className="row row-images"
                        style={{ textAlign: "center" }}
                    >
                        <div className="column">
                            <Link reloadDocument to="/gardening">
                                <h2>Gardening</h2>
                                <img
                                    className="content-img"
                                    style={{ height: 115 + "px" }}
                                    src="/images/gardening_avatar.jpg"
                                    alt="Blue cat gardening"
                                />
                            </Link>
                        </div>
                        <div className="column">
                            <Link reloadDocument to="/fishing">
                                <h2>Fishing</h2>
                                <img
                                    className="content-img"
                                    style={{ height: 115 + "px" }}
                                    src="/images/fishing_avatar.png"
                                    alt="Toons fishing"
                                />
                            </Link>
                        </div>
                        <div className="column">
                            <Link reloadDocument to="/racing">
                                <h2>Racing</h2>
                                <img
                                    className="content-img"
                                    style={{ height: 115 + "px" }}
                                    src="/images/racing_avatar.png"
                                    alt="Toons racing"
                                />
                            </Link>
                        </div>
                        <div className="column">
                            <Link reloadDocument to="/golfing">
                                <h2>Golfing</h2>
                                <img
                                    className="content-img"
                                    style={{ height: 115 + "px" }}
                                    src="/images/golfing_avatar.jpg"
                                    alt="Golf ball in the air"
                                />
                            </Link>
                        </div>
                        <div className="column">
                            <Link reloadDocument to="/doodles">
                                <h2>Doodles</h2>
                                <img
                                    className="content-img"
                                    style={{ height: 115 + "px" }}
                                    src="/images/doodles_avatar.jpg"
                                    alt="Doodle at pet shop"
                                />
                            </Link>
                        </div>
                    </div>
                    <br />
                    <hr />
                    <div className="panel-nav">
                        <a href="#sillymeter">Silly Meter</a>
                        <a href="#trolley">The Trolley</a>
                        <a href="#parties">Parties</a>
                        <a href="#cartoonival">Cartoonival</a>
                    </div>
                    <span className="anchor" id="sillymeter"></span>
                    <h2>Silly Meter</h2>
                    <p>
                        The Silly Meter is a machine, located at Toon Hall in
                        Toontown Central, that tracks silliness across Toontown.
                        The Silly Meter goes through 3 phases: Active, Reward,
                        and Cooldown.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 300 + "px" }}
                        src="/images/silly_meter.PNG"
                        alt="Silly Meter"
                    />
                    <h3>Active Phase</h3>
                    <p>
                        This phase is where toons can earn Silly Points to push
                        the Silly Meter to its maximum level. Toons can earn
                        Silly Points by participating in all sorts of
                        activities. Activities include, but are not limited to,
                        defeating cogs, rescuing buildings, throwing a party, or
                        racing in Goofy Speedway. On the other hand, cog
                        activities, such as invading districts or taking over
                        toon buildings, have a negative impact on the Silly
                        Meter levels.
                    </p>
                    <h4>Silly Reader</h4>
                    <p>
                        To track the Silly Meter's progress using the Silly
                        Reader, open the Shticker book and navigate to the Silly
                        Meter tab{" "}
                        <span>
                            <img
                                className="book-tab"
                                src="/images/silly_meter_tab.PNG"
                                alt="Silly Meter book tab"
                            />
                        </span>
                        . Here, a toon can keep track of the Silly Meter's
                        status, view their own Silly Points, and view global
                        Silly Points. Talk to any member of the Loony Labs in
                        Toon Hall about obtaining the silly reader. A toon must
                        be at last 30 laff and/or have a level four gag in order
                        to gain a silly reader.
                    </p>
                    <h4>Silly Teams</h4>
                    <p>
                        Available on the Silly Reader page in the Shticker book
                        is the option to choose a Silly Team. A Silly Team
                        represents a reward that could be activated once the
                        Silly Meter is full. During the active phase, 3 teams
                        are avaiable to join. There are many possible teams that
                        could appear in many combinations. By joining a Silly
                        Team, a toons Silly Points will be put towards that
                        reward. The team with the most points when the Silly
                        Meter reaches its capacity will be the reward all toons
                        in Toontown will receive.
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>Team</th>
                                <th style={{ width: 40 + "%" }}>Description</th>
                                <th>Badge(s)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Overjoyed Laff Meters</td>
                                <td>
                                    +8 extra laff points to maximum laff points
                                </td>
                                <td>
                                    <img
                                        src="/images/Sillymeter_laffteam.png"
                                        alt="Laff Team"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Decreased Fish Rarity</td>
                                <td>Rare fish are easier to catch</td>
                                <td>
                                    <img
                                        src="/images/sillymeter_fishteam.png"
                                        alt="Fish Team"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Double Jellybeans</td>
                                <td>
                                    All jellybeans rewarded from activities and
                                    unites are doubled
                                </td>
                                <td>
                                    <img
                                        src="/images/Sillymeter_beanteam.png"
                                        alt="Jellybean Team"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Speedy Garden Growth</td>
                                <td>
                                    Gardens grow every six hours (12AM, 6AM,
                                    12PM, 6PM)
                                </td>
                                <td>
                                    <img
                                        src="/images/Sillymeter_gardenteam.png"
                                        alt="Garden Team"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Double Racing Tickets</td>
                                <td>
                                    Double tickets are rewarded in non-Grand
                                    Prix races
                                </td>
                                <td>
                                    <img
                                        src="/images/Sillymeter_racingteam.png"
                                        alt="Racing Team"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Global Teleport Access</td>
                                <td>
                                    Toons have teleportation access to anywhere
                                    in Toontown
                                </td>
                                <td>
                                    <img
                                        src="/images/Sillymeter_teleportteam.png"
                                        alt="Teleport Team"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Doodle Trick Boost</td>
                                <td>
                                    Doodle tricks will be more successful and
                                    earn more experience points
                                </td>
                                <td>
                                    <img
                                        src="/images/Sillymeter_trickteam.png"
                                        alt="Trick Team"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Double Gag Experience</td>
                                <td>
                                    There are seven gag experience teams, one
                                    for each existing <Link reloadDocument to="/gags">gag track</Link>.
                                    When one is active, double gag experience for that
                                    track is given in all battles.
                                </td>
                                <td>
                                    <img
                                        src="/images/Sillymeter_toonupteam.png"
                                        alt="Toonup Team"
                                    />
                                    <img
                                        src="/images/Sillymeter_trapteam.png"
                                        alt="Trap Team"
                                    />
                                    <br />
                                    <img
                                        src="/images/Sillymeter_lureteam.png"
                                        alt="Lure Team"
                                    />
                                    <img
                                        src="/images/Sillymeter_soundteam.png"
                                        alt="Sound Team"
                                    />
                                    <br />
                                    <img
                                        src="/images/Sillymeter_throwteam.png"
                                        alt="Throw Team"
                                    />
                                    <img
                                        src="/images/Sillymeter_squirtteam.png"
                                        alt="Squirt Team"
                                    />
                                    <img
                                        src="/images/Sillymeter_dropteam.png"
                                        alt="Drop Team"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h3>Reward Phase</h3>
                    <p>
                        This phase is activated once the Silly Meter reaches its
                        maximum level and lasts for 2 days. During this phase,
                        all toons receive a special reward. The special reward
                        is determined by Silly Teams, as mentioned in the Active
                        Phase section above.
                    </p>
                    <h3>Cooldown Pase</h3>
                    <p>
                        Lasting for two days, this phase allows the Silly Meter
                        levels to relax. Once the Silly Meter has reset, it
                        moves back to the active phase.
                    </p>
                    <span className="anchor" id="trolley"></span>
                    <h2>The Trolley</h2>
                    <p>
                        The Trolley is a vehicle in every playground, except for
                        Chip N' Dales Acorn Acres and Goofy Speedway, that takes
                        toons to play mini-games to earn jellybeans. Trolley
                        games get more difficult as you visit higher level
                        playgrounds. Games played in Toontown Central will be
                        much easier than games in Donald's Dreamland. A toon can
                        play mini-games by themselves or with up to 3 other
                        toons. Some mini-games are only playable with a minimum
                        of two toons, such as, Match Minne, Race Game, Toon Tag,
                        and Ice Slide.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 300 + "px" }}
                        src="/images/trolley.PNG"
                        alt="Trolley in TTC"
                    />
                    <h3>Mini-games</h3>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: 25 + "%" }}>Game</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Cannon Game</td>
                                <td>
                                    The objective of this game is to launch your
                                    toon ou of a cannon and into a water tower
                                    located in a unique spot each game. There is
                                    a jellybean counter that counts down the
                                    longer it takes to get into the tower. The
                                    number on the counter upon completion is the
                                    amount of jellybeans rewarded. If multiple
                                    toons are playing, the first person to get
                                    their toon into the water tower wins for
                                    everyone.
                                </td>
                            </tr>
                            <tr>
                                <td>Catching Game</td>
                                <td>
                                    The objective of this game is to catch as
                                    much fruit falling from trees as possible.
                                    Watch out for failing anvils and crossing
                                    cogs, they will slow you down!
                                </td>
                            </tr>
                            <tr>
                                <td>Cog Thief</td>
                                <td>
                                    The goal of this game is stop cogs from
                                    stealing gag barrels. A toon will throw pies
                                    at incoming cogs. At least one barrel must
                                    be saved in order to receive jellybeans.
                                </td>
                            </tr>
                            <tr>
                                <td>Jungle Vines</td>
                                <td>
                                    The objective of this game is to jump across
                                    vines, to get as far as possible. Making it
                                    all the way to the end will reward the most
                                    jellybeans. Try to collect bananas along the
                                    way. Watch out for flying bats and spiders
                                    on the vines, they will knock you off your
                                    vine!
                                </td>
                            </tr>
                            <tr>
                                <td>Maze Game</td>
                                <td>
                                    The objective is to collect as many tokens
                                    as possible around the maze. Flunkies walk
                                    around at different speeds, some super slow
                                    and some faster than toons. If a toon comes
                                    in contact with a Flunky, they will be sent
                                    flying into the air and land in a random
                                    spot.
                                </td>
                            </tr>
                            <tr>
                                <td>Photo Fun</td>
                                <td>
                                    The goal of this game is to take the best
                                    photo of a depicted toon, including specific
                                    poses or animations. Desired photos to be
                                    taken are shown on the bottom of the screen.
                                    With multiple players, each toon competes to
                                    take the best photos.
                                </td>
                            </tr>
                            <tr>
                                <td>Ring Game</td>
                                <td>
                                    There are 16 rings in an underwater course
                                    (32 if there are two toons, 48 if there are
                                    three toons, and 64 if there are four
                                    toons). The objective is to swim through as
                                    many of those rings as possible. If there
                                    are multiple toons, each toon has an
                                    assigned ring color they must swim through,
                                    otherwise it wont count.
                                </td>
                            </tr>
                            <tr>
                                <td>Toon Escape</td>
                                <td>
                                    The goal of this game is to gather as many
                                    points as possible while also getting to the
                                    end elevator in time. Toons must run through
                                    a platforming course and collect cog emblems
                                    while avoiding obstacles, such as pits,
                                    cogs, and stompers.
                                </td>
                            </tr>
                            <tr>
                                <td>Toon Memory Game</td>
                                <td>
                                    The objective is to match all cards in as
                                    few flips as possible. A toon must flip the
                                    cards over to reveal an image and find a
                                    matching card hidden somewhere on the board.
                                    If playing with another toon, a toon can
                                    only flip one card at a time.
                                </td>
                            </tr>
                            <tr>
                                <td>Toon Slingshot</td>
                                <td>
                                    The goal of this game is to slingshot your
                                    toon and land on the highest yielding point
                                    target. The smaller the target, the bigger
                                    the reward. Each toon has an umbrella,
                                    acting like a parachute. Trampolines are
                                    scattered around the course, allowing toons
                                    to bounce back in the air if they land on
                                    them.
                                </td>
                            </tr>
                            <tr>
                                <td>Treasure Dive</td>
                                <td>
                                    The objective is to collect treasure chests
                                    from the bottom of the water pool and bring
                                    it back to the boat. Watch out for swimming
                                    fish, they will slow you down!
                                </td>
                            </tr>
                            <tr>
                                <td>Tug of War</td>
                                <td>
                                    The object of tug-of-war is to pull the
                                    opposing cog into the water. If multiple
                                    toons are playing, it is randomly decided if
                                    all toons will be working as a team against
                                    a lone cog or whether they will battle each
                                    other.
                                </td>
                            </tr>
                            <tr>
                                <td>Ice Slide</td>
                                <td>
                                    The objective is to get as close to the
                                    center of the target in the middle of the
                                    course as possible. Each toon is inside a
                                    tire and starts at the four corners of the
                                    course, moving one time each round. Be
                                    careful, some opponents might knock you out
                                    of the way!
                                </td>
                            </tr>
                            <tr>
                                <td>Match Minnie</td>
                                <td>
                                    The goal of this game is to follow the dance
                                    sequence given by Minnie as fast as possible
                                    in the correct order. Sequences are a random
                                    order of arrow keys. Hitting an incorrect
                                    key automatically results in failing a
                                    round.
                                </td>
                            </tr>
                            <tr>
                                <td>Race Game</td>
                                <td>
                                    The objective of this game is to reach the
                                    finish line first. For every round, toons
                                    must pick a number between one through four
                                    on a die. If any Toon chooses the same
                                    number as another Toon, they will not
                                    advance. The number chosen will be the
                                    number of spaces a toon will move forward.
                                </td>
                            </tr>
                            <tr>
                                <td>Toon Tag</td>
                                <td>
                                    Similar to real life tag, the goal is avoid
                                    being "IT" while collecting ice cream cones
                                    scattered around. The "IT" toon has to tag
                                    another toon before collecting ice cream
                                    cones themself.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span className="anchor" id="parties"></span>
                    <h2>Parties</h2>
                    <p>
                        Toon parties are where toons can join random toons or
                        their friends to play all sorts of mini-games. If you
                        have been to Toon Fest, these mini-games may be
                        familiar! There is a party gate in each playground
                        (shown below) where a toon can join exisiting parties or
                        create their very own party. Parties typically last for
                        30 minutes. It costs jellybeans to make a party, but
                        toons can also earn jellybeans by playing party games.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 350 + "px" }}
                        src="/images/party_gate.PNG"
                        alt="TTC Party Gate"
                    />
                    <h3>Hosting a Party</h3>
                    <p>
                        To plan a party, walk up to a Party Planner located on
                        either side of the party gate. It will cost 100
                        jellybeans to start planning. While designing a party,
                        party items can be placed anywhere on the party grounds.
                        Party items include mini-games, dance floor, party
                        clock, jukeboxes, fireworks, and decorations. Each item
                        costs jellybeans. The more items oon the party grounds,
                        the more jellybeans the party will cost.
                    </p>
                    <p>
                        Once a toon finishes creating a party, they will be able
                        to set a date and time the party will take place. The
                        party can also be public or private. Private parties are
                        only available to invited toons.
                    </p>
                    <iframe
                        className="video-frame"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/IavSwRFoso0"
                        title="Toontown Rewritten Party Planning How-to"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen>
                    </iframe>
                    <span className="anchor" id="cartoonival"></span>
                    <h2>Cartoonival</h2>
                    <p>
                        The Cartoonival, previously known as ToonFest, is an
                        annual event that celebrates Toontown.
                        Every year on September 19th, the anniversary of
                        Disney's Toontown Online closure, the Cartoonival
                        grounds open up for toons to visit and have some fun!
                        This area stays open until October 20th. The area is
                        accessible from Chip N' Dales Acorn Acres.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 300 + "px" }}
                        src="/images/cartoonival_tower.jpg"
                        alt="Cartoonival tower"
                    />
                    <p>
                        The Cartoonival grounds is full of fun decorations and
                        activites toons can partake in to earn Cartoonival tokens.
                        Activities include trampolines, cannons, fishing, picnic
                        games, and the duck tank.
                        Cartoonival tokens can be used to purchase{" "}
                        <a
                            href="https://toontownrewritten.fandom.com/wiki/Category:Cartoonival"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            exclusive prizes
                        </a>! These prizes include pies to throw at the duck tank,
                        merry multipliers, and items such as accessories, clothing,
                        or furniture.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 300 + "px" }}
                        src="/images/cartoonival_duck_tank.png"
                        alt="Cartoonival Duck Tank"
                    />
                    <h4>Cartoonival ToonTasks</h4>
                    <p>
                        Another way to earn tokens is to complete toontasks given out
                        by Token Taskers. Token Taskers can be found at the Cartoonival
                        or scattered around every playground. There is a random assortment
                        of simple tasks that reward Cartoonival tokens.
                        Additionally, Token Taskers offer toontasks that have two
                        limited-time rewards. The Fluffy Doodle Pack or five "25 token"
                        unites.
                    </p>
                    <img
                        className="content-img"
                        style={{ height: 270 + "px" }}
                        src="/images/tokentaskers.png"
                        alt="Cartoonival Token Taskers"
                    />
                    <h4>Merry Multipliers</h4>
                    <p>
                        Merry Multipliers double cog promotion xp or gag skill points
                        for given period of time. These can be purchased at the Cartoonival
                        at the multipliers booths.
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Duration</th>
                                <th>
                                    <img
                                        style={{ padding: 2 + "px" }}
                                        src="/images/cartoonival_token.png"
                                        alt="Jellybeans"
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Gag or Promotion XP</td>
                                <td>1 hour</td>
                                <td>75 tokens</td>
                            </tr>
                            <tr>
                                <td>Gag or Promotion XP</td>
                                <td>2 hours</td>
                                <td>150 tokens</td>
                            </tr>
                            <tr>
                                <td>Gag or Promotion XP</td>
                                <td>6 hours</td>
                                <td>300 tokens</td>
                            </tr>
                            <tr>
                                <td>Gag or Promotion XP</td>
                                <td>12 hours</td>
                                <td>600 tokens</td>
                            </tr>
                            <tr>
                                <td>Gag or Promotion XP</td>
                                <td>1 day</td>
                                <td>1100 tokens</td>
                            </tr>
                            <tr>
                                <td>Gag or Promotion XP</td>
                                <td>2 days</td>
                                <td>2000 tokens</td>
                            </tr>
                            <tr>
                                <td>All Battle XP</td>
                                <td>1 hour</td>
                                <td>125 tokens</td>
                            </tr>
                            <tr>
                                <td>All Battle XP</td>
                                <td>2 hours</td>
                                <td>225 tokens</td>
                            </tr>
                            <tr>
                                <td>All Battle XP</td>
                                <td>6 hours</td>
                                <td>450 tokens</td>
                            </tr>
                            <tr>
                                <td>All Battle XP</td>
                                <td>12 hours</td>
                                <td>900 tokens</td>
                            </tr>
                            <tr>
                                <td>All Battle XP</td>
                                <td>1 day</td>
                                <td>1600 tokens</td>
                            </tr>
                            <tr>
                                <td>All Battle XP</td>
                                <td>2 days</td>
                                <td>3000 tokens</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>Note that "All Battle XP" means both gag skill points and promotion XP.</p>
                    <h4>Gag Skill Points</h4>
                    <p>
                        For the duration of the Cartoonival, the gag skill point limit
                        is increased from 200 to 600. This is applied to toons who have
                        merry multipliers active or not.
                    </p>
                    <br />
                    <br />
                    <div className="panel-nav">
                        <a href="#sillymeter">Silly Meter</a>
                        <a href="#trolley">The Trolley</a>
                        <a href="#parties">Parties</a>
                        <a href="#cartoonival">Cartoonival</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Activities;
